export const MAINMAP_PLACE_CONSTANT = {
  GET_START: 'LI/MAINMAP/PLACE/GET/get/requested',
  GET_FAILED: 'LI/MAINMAP/PLACE/GET/get/failed',
  GET_SUCCESS: 'LI/MAINMAP/PLACE/GET/get/success',

  GET_COUNTRY_START: 'LI/MAINMAP/PLACE/COUNTRY/get/requested',
  GET_COUNTRY_FAILED: 'LI/MAINMAP/PLACE/COUNTRY/get/failed',
  GET_COUNTRY_SUCCESS: 'LI/MAINMAP/PLACE/COUNTRY/get/success',

  GET_PROVINCE_INTER_START: 'LI/MAINMAP/PLACE/PROVINCE_INTER/get/requested',
  GET_PROVINCE_INTER_FAILED: 'LI/MAINMAP/PLACE/PROVINCE_INTER/get/failed',
  GET_PROVINCE_INTER_SUCCESS: 'LI/MAINMAP/PLACE/PROVINCE_INTER/get/success',

  GET_CITY_INTER_START: 'LI/MAINMAP/PLACE/CITY_INTER/get/requested',
  GET_CITY_INTER_FAILED: 'LI/MAINMAP/PLACE/CITY_INTER/get/failed',
  GET_CITY_INTER_SUCCESS: 'LI/MAINMAP/PLACE/CITY_INTER/get/success',

  GET_DISTRICT_INTER_START: 'LI/MAINMAP/PLACE/DISTRICT_INTER/get/requested',
  GET_DISTRICT_INTER_FAILED: 'LI/MAINMAP/PLACE/DISTRICT_INTER/get/failed',
  GET_DISTRICT_INTER_SUCCESS: 'LI/MAINMAP/PLACE/DISTRICT_INTER/get/success',

  GET_PROVINCE_START: 'LI/MAINMAP/PLACE/PROVINCE/get/requested',
  GET_PROVINCE_FAILED: 'LI/MAINMAP/PLACE/PROVINCE/get/failed',
  GET_PROVINCE_SUCCESS: 'LI/MAINMAP/PLACE/PROVINCE/get/success',

  GET_CITY_START: 'LI/MAINMAP/PLACE/CITY/get/requested',
  GET_CITY_FAILED: 'LI/MAINMAP/PLACE/CITY/get/failed',
  GET_CITY_SUCCESS: 'LI/MAINMAP/PLACE/CITY/get/success',

  GET_DISTRICT_START: 'LI/MAINMAP/PLACE/DISTRICT/get/requested',
  GET_DISTRICT_FAILED: 'LI/MAINMAP/PLACE/DISTRICT/get/failed',
  GET_DISTRICT_SUCCESS: 'LI/MAINMAP/PLACE/DISTRICT/get/success',

  GET_VILLAGE_START: 'LI/MAINMAP/PLACE/VILLAGE/get/requested',
  GET_VILLAGE_FAILED: 'LI/MAINMAP/PLACE/VILLAGE/get/failed',
  GET_VILLAGE_SUCCESS: 'LI/MAINMAP/PLACE/VILLAGE/get/success',

  GET_ADVANCE_START: 'LI/MAINMAP/PLACE/ADVANCE/get/requested',
  GET_ADVANCE_FAILED: 'LI/MAINMAP/PLACE/ADVANCE/get/failed',
  GET_ADVANCE_SUCCESS: 'LI/MAINMAP/PLACE/ADVANCE/get/success',

  GET_ADVANCE_INTER_START: 'LI/MAINMAP/PLACE/ADVANCE-INTER/get/requested',
  GET_ADVANCE_INTER_FAILED: 'LI/MAINMAP/PLACE/ADVANCE-INTER/get/failed',
  GET_ADVANCE_INTER_SUCCESS: 'LI/MAINMAP/PLACE/ADVANCE-INTER/get/success',

  // GET_ADVANCE_DATEX_START: 'LI/MAINMAP/PLACE/ADVANCE_DATEX/get/requested',
  // GET_ADVANCE_DATEX_FAILED: 'LI/MAINMAP/PLACE/ADVANCE_DATEX/get/failed',
  // GET_ADVANCE_DATEX_SUCCESS: 'LI/MAINMAP/PLACE/ADVANCE_DATEX/get/success',
  // RESET_ADVANCE_DATEX: 'LI/MAINMAP/PLACE/ADVANCE_DATEX/reset',

  GET_COORDINATES_START: 'LI/MAINMAP/PLACE/COORDINATES/get/requested',
  GET_COORDINATES_FAILED: 'LI/MAINMAP/PLACE/COORDINATES/get/failed',
  GET_COORDINATES_SUCCESS: 'LI/MAINMAP/PLACE/COORDINATES/get/success',

  GET_SEARCH_START: 'LI/MAINMAP/PLACE/SEARCH/get/requested',
  GET_SEARCH_FAILED: 'LI/MAINMAP/PLACE/SEARCH/get/failed',
  GET_SEARCH_SUCCESS: 'LI/MAINMAP/PLACE/SEARCH/get/success',

  SET_COUNTRY_MANDALA: 'LI/MAINMAP/PLACE/SET/COUNTRY',

  SET_GEOJSON: 'LI/MAINMAP/PLACE/SET/GEOJSON',
  SET_PARAMS: 'LI/MAINMAP/PLACE/SET/PARAMS',
  REMOVE_PARAMS: 'LI/MAINMAP/PLACE/REMOVE/PARAMS',

  SET_SEARCH_LOCATION_DISABLED: 'LI/MAINMAP/PLACE/SET_SEARCH_LOCATION_DISABLED',

  RESET_STATUS: 'LI/MAINMAP/PLACE/reset-status',
  RESET_ALL: 'LI/MAINMAP/PLACE/reset-all',
};

import React from 'react';
import PropTypes from 'prop-types';
import { Popover } from '@bvt-atoms';
/**
 * @author Randa <m.randa@bvarta.com>
 * @copyright PT Bhumi Varta Technology
 * @param {object} props
 * @param {string} props.color Hexadecimal color or css rgba
 * @param {string} props.title
 * @param {boolean} props.isActive
 * @param {JSX.Element} props.iconSlot
 * @param {number} props.totalCategory
 * @param {number} props.totalBrand
 * @param {number} props.style
 * @param {number} props.className
 * @param {Function} props.onCardClick
 * @param {Function} props.onMoreClick
 */
const ColorSelectedCard = (props) => {
  const {
    color,
    title,
    iconSlot,
    isActive,
    onCardClick,
    onMoreClick,
    totalCategory,
    totalCategorySelect,
    style,
    className,
    checkAll,
    disabled,
  } = props;

  const internalHandleCardClick = (e) => {
    e.preventDefault();
    onCardClick && onCardClick();
  };

  const internalHandleMoreClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onMoreClick && onMoreClick();
  };

  const PopoverContent = () => {
    return (
      <div className='ColorSelectedCard container-popover'>
        <div className='ColorSelectedCard arrow arrow---down' />
        <p className='ColorSelectedCard container-popover-text'>
          Contact our sales to subscribe
        </p>
      </div>
    );
  };

  return (
    <>
      {disabled ? (
        <Popover
          className='ColorSelectedCard__popover-wrapper'
          content={<PopoverContent />}
          header={false}
          placement='top'
          trigger='hover'
          visibility={disabled}
        >
          <div
            className={`ColorSelectedCard__container ${className} ${
              disabled && 'disabled'
            }`}
            onClick={internalHandleCardClick}
            style={{
              '--ColorSelectedCardColor': color,
              '--ColorSelectedCardColorLighter': color,
              '--ColorSelectedCardOpacity': isActive ? 1 : 0,
              '--ColorSelectedCardHeight': isActive ? '84%' : '0%',
              '--ColorSelectedCardTextColor': '#616477',
              ...style,
            }}
          >
            {checkAll && (
              <div className='ColorSelectedCard__checklist-wrapper'>
                <svg className='checklist-svg' viewBox='0 0 11.279 11.279'>
                  <path d='M13732.6,6189.381a1.6,1.6,0,0,1-1.6-1.6v-3.191a1.6,1.6,0,0,1,3.191,0v1.6h4.783a1.6,1.6,0,1,1,0,3.19Z' />
                </svg>
              </div>
            )}

            <div className='ColorSelectedCard__wrapper-top' />
            <div className='ColorSelectedCard__icon-bubble'>{iconSlot}</div>
            <div className='ColorSelectedCard__wrapper-bottom'>
              <div className='ColorSelectedCard__middle-text'>{title}</div>

              <div className='ColorSelectedCard__total-container'>
                {checkAll && (
                  <div className='ColorSelectedCard__total-text'>
                    {' '}
                    Category <b>{totalCategory}</b>
                  </div>
                )}
              </div>
              {totalCategorySelect === 0 ? (
                <></>
              ) : (
                <div className='ColorSelectedCard__total-text'>
                  Category <b>{totalCategorySelect}</b>
                </div>
              )}
              <div
                className='ColorSelectedCard__more-text'
                onClick={internalHandleMoreClick}
              >
                More
                <svg className='dropdown-svg' viewBox='0 0 8.893 8.893'>
                  <path d='M1.479,6.288A1.48,1.48,0,0,1,0,4.809V1.479a1.48,1.48,0,0,1,2.959,0v1.85H4.809a1.48,1.48,0,1,1,0,2.959Z' />
                </svg>
              </div>
            </div>
          </div>
        </Popover>
      ) : (
        <div
          className={`ColorSelectedCard__container ${className} ${
            disabled && 'disabled'
          }`}
          onClick={internalHandleCardClick}
          style={{
            '--ColorSelectedCardColor': color,
            '--ColorSelectedCardColorLighter': color,
            '--ColorSelectedCardOpacity': isActive ? 1 : 0,
            '--ColorSelectedCardHeight': isActive ? '84%' : '0%',
            '--ColorSelectedCardTextColor': '#616477',
            ...style,
          }}
        >
          {checkAll && (
            <div className='ColorSelectedCard__checklist-wrapper'>
              <svg className='checklist-svg' viewBox='0 0 11.279 11.279'>
                <path d='M13732.6,6189.381a1.6,1.6,0,0,1-1.6-1.6v-3.191a1.6,1.6,0,0,1,3.191,0v1.6h4.783a1.6,1.6,0,1,1,0,3.19Z' />
              </svg>
            </div>
          )}

          <div className='ColorSelectedCard__wrapper-top' />
          <div className='ColorSelectedCard__icon-bubble'>{iconSlot}</div>
          <div className='ColorSelectedCard__wrapper-bottom'>
            <div className='ColorSelectedCard__middle-text'>{title}</div>

            <div className='ColorSelectedCard__total-container'>
              {checkAll && (
                <div className='ColorSelectedCard__total-text'>
                  {' '}
                  Category <b>{totalCategory}</b>
                </div>
              )}
            </div>
            {totalCategorySelect === 0 ? (
              <></>
            ) : (
              <div className='ColorSelectedCard__total-text'>
                Category <b>{totalCategorySelect}</b>
              </div>
            )}
            <div
              className='ColorSelectedCard__more-text'
              onClick={internalHandleMoreClick}
            >
              More
              <svg className='dropdown-svg' viewBox='0 0 8.893 8.893'>
                <path d='M1.479,6.288A1.48,1.48,0,0,1,0,4.809V1.479a1.48,1.48,0,0,1,2.959,0v1.85H4.809a1.48,1.48,0,1,1,0,2.959Z' />
              </svg>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

ColorSelectedCard.defaultProps = {
  checkAll: false,
  className: undefined,
  color: '#D2D2D2',
  iconSlot: undefined,
  isActive: false,
  onCardClick: undefined,
  onMoreClick: undefined,
  setCheckAll: undefined,
  style: {},
  title: undefined,
  totalBrand: undefined,
  totalCategory: undefined,
};
ColorSelectedCard.propTypes = {
  checkAll: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  iconSlot: PropTypes.element,
  isActive: PropTypes.bool,
  onCardClick: PropTypes.func,
  onMoreClick: PropTypes.func,
  setCheckAll: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
  style: PropTypes.object,
  title: PropTypes.string,
  totalBrand: PropTypes.number,
  totalCategory: PropTypes.number,
};

export default ColorSelectedCard;

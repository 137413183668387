import React, { useRef } from 'react';

import './PopupGrid.scss';
import { area } from '@turf/turf';
import { toTitleCase } from '@bvt-utils';
import { Popup } from 'react-leaflet';

/**
 * @author Joko<joko.priyono@bvarta.com>
 * @description Popup data for grid datex telco data;
 * @param {object|undefined} props
 * @param {import('geojson').Polygon|undefined} props.feature
 * @param {string} props.id
 * @param {object} props.properties
 * @param {string} props.properties.index
 * @param {object} props.properties.density
 * @param {number} props.properties.density.counts
 * @param {string} props.properties.density.legend_class
 */
export const PopupGrid = (props) => {
  const { feature } = props;
  let density = feature?.properties?.density.legend_class;
  let background;

  /**
   * @type {React.MutableRefObject<import('leaflet').Popup>}
   */
  const popupref = useRef();

  switch (density) {
    case 'class 1':
      background = '#DAF6EF';
      density = 'Low Density';
      break;
    case 'class 2':
      background = '#89E1CD';
      density = 'Medium Low Density';
      break;
    case 'class 3':
      background = '#2FB698';
      density = 'Medium Density';
      break;
    case 'class 4':
      background = '#248E76';
      density = 'Medium  High Density';
      break;
    case 'class 5':
      background = '#165547';
      density = 'High Density';
      break;
    default:
      density = '??? Density';
  }
  const icon = (
    <svg
      height='0.5rem'
      viewBox='0 0 12.263 12.263'
      width='0.5rem'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.153,7.489V5.518H1.183a1.182,1.182,0,1,1,0-2.364h1.97V1.183a1.183,1.183,0,1,1,2.365,0V3.154H7.489a1.182,1.182,0,1,1,0,2.364H5.518V7.489a1.183,1.183,0,1,1-2.365,0Z'
        fill='#616477'
        transform='translate(6.132) rotate(45)'
      />
    </svg>
  );

  return (
    <Popup className='request-popup' interactive={true} ref={popupref}>
      <div className='datex-popup-grid'>
        <div className='datex-popup-grid-body'>
          <div className='datex-popup-grid-body-close'>
            <div
              className='datex-popup-grid-body-close-button'
              onClick={() => popupref.current.close()}
            >
              {icon}
            </div>
          </div>
          <div className='datex-popup-grid-body-area-info'>
            <svg
              fill='none'
              height='16'
              viewBox='0 0 16 16'
              width='16'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M8.00232 0.888916C6.58869 0.888916 5.23297 1.45365 4.23338 2.45887C3.2338 3.46409 2.67224 4.82747 2.67224 6.24907C2.62479 7.22195 2.88574 8.18485 3.41747 8.99899L8.00232 15.1111L12.5872 9.00069C13.0931 8.17506 13.3518 7.22037 13.3324 6.25078C13.3326 5.54673 13.1949 4.84953 12.9272 4.19901C12.6594 3.54849 12.2668 2.95739 11.7719 2.45947C11.2769 1.96156 10.6892 1.56658 10.0424 1.2971C9.39566 1.02762 8.70242 0.888916 8.00232 0.888916V0.888916ZM8.00232 3.99396C8.44604 3.99396 8.87979 4.12629 9.24872 4.37422C9.61765 4.62214 9.90518 4.97453 10.0749 5.3868C10.2447 5.79908 10.2891 6.25272 10.2025 6.69036C10.1158 7.128 9.90209 7.52997 9.58828 7.84544C9.27446 8.16091 8.87466 8.3757 8.43945 8.46265C8.00423 8.5496 7.55315 8.5048 7.14326 8.33392C6.73336 8.16305 6.38306 7.87376 6.13666 7.50266C5.89027 7.13155 5.75884 6.6953 5.75901 6.24907C5.75924 5.6509 5.99568 5.07731 6.41636 4.65442C6.83704 4.23152 7.4075 3.99396 8.00232 3.99396V3.99396Z'
                fill='url(#paint0_linear_3821_46715)'
              />
              <defs>
                <linearGradient
                  gradientUnits='userSpaceOnUse'
                  id='paint0_linear_3821_46715'
                  x1='2.66675'
                  x2='13.3334'
                  y1='7.69085'
                  y2='7.69085'
                >
                  <stop stopColor='#FF7A00' />
                  <stop offset='1' stopColor='#FFBE8F' />
                </linearGradient>
              </defs>
            </svg>
            <div className='datex-popup-grid-body-area-info-text'>
              <div className='datex-popup-grid-body-area-info-text-title'>
                {toTitleCase(feature?.properties?.administrative?.[0] || '')}
              </div>
              <div className='datex-popup-grid-body-area-info-text-sub-title'>
                {toTitleCase(feature.properties.administrative?.[1] || '')},
                {toTitleCase(feature?.properties?.administrative?.[2] || '')}
                ,
                {toTitleCase(feature?.properties?.administrative?.[3] || '')}
                ,
              </div>
            </div>
          </div>
          <div className='datex-popup-grid-body-count'>
            <div className='datex-popup-grid-body-count-container'>
              <div className='datex-popup-grid-body-count-text'>Counts</div>
              <div className='datex-popup-grid-body-count-container-text'>
                <div className='datex-popup-grid-body-count-number'>
                  {feature?.properties.density.counts}
                </div>
                <div className='datex-popup-grid-body-count-text'>Devices</div>
              </div>
            </div>
          </div>
          <div className='datex-popup-grid-body-info'>
            <div className='datex-popup-grid-body-info-column'>
              <div className='datex-popup-grid-body-info-text'>Grid Style</div>
              <div className='datex-popup-grid-body-info-text'>ID</div>
              <div className='datex-popup-grid-body-info-text'>Grid Size</div>
            </div>
            <div className='datex-popup-grid-body-info-column'>
              <div className='datex-popup-grid-body-info-text'>:</div>
              <div className='datex-popup-grid-body-info-text'>:</div>
              <div className='datex-popup-grid-body-info-text'>:</div>
            </div>
            <div className='datex-popup-grid-body-info-column'>
              <div className='datex-popup-grid-body-info-text-row'>
                <div
                  className='datex-popup-grid-body-info-text-block'
                  style={{ background }}
                />
                <div className='datex-popup-grid-body-info-text'>{density}</div>
              </div>
              <div className='datex-popup-grid-body-info-text-bold'>
                {feature?.properties.index}
              </div>
              <div className='datex-popup-grid-body-info-text-bold'>
                {feature && (area(feature) / 1000000).toFixed(1)} k&#13217;
              </div>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  );
};

import {
  DatexPOIService,
  DatexService,
  DatexThematicService,
} from '../service';

const { createAsyncThunk } = require('@reduxjs/toolkit');

/**
 * @copyright PT Bhumi Varta Techonology
 * @author Renta<renta.yustika@bvarta.com>
 */

const datexService = new DatexService();
const datexPOIService = new DatexPOIService();
const datexThematicService = new DatexThematicService();

const doAnalyzeTelcoData = createAsyncThunk(
  'LI/mapbro/datex/do-analyze',
  /**
   * @description For do analyze telco data
   * @param {Object} param
   * @param {number} param.id
   * @param {string} param.name
   * @param {number} param.step
   */
  async (
    {
      id,
      kode_admin,
      id_source,
      type,
      start_date,
      end_date,
      time,
      density_type,
    },
    { rejectWithValue }
  ) => {
    try {
      const dataResponse = await datexService.AnalyzeTelcoData({
        kode_admin,
        id_source,
        type,
        start_date,
        end_date,
        time,
        density_type,
      });
      return { id, ...dataResponse };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const doAnalyzeDatexPoi = createAsyncThunk(
  'LI/mapbro/datex/do-analyze-poi',
  /**
   * @param {Object} param
   * @param {Number} param.id
   * @param {Number} param.categoryId
   * @param {Array<Number>} param.brands
   * @param {Array<String>} param.criterias
   * @param {Number} param.provinceId
   * @param {Number|undefined} param.cityId
   * @param {Number|undefined} param.districtId
   * @param {Number|undefined} param.villageId
   * @param {Number|undefined} param.countryId
   */

  async (
    {
      id,
      categoryId,
      brands,
      criterias,
      provinceId,
      cityId,
      districtId,
      villageId,
      countryId=1,
    },
    { rejectWithValue }
  ) => {
    try {
      const dataResponse = await datexPOIService.AnalyzeDatexPoi({
        categoryId,
        brands,
        criterias,
        provinceId,
        cityId,
        districtId,
        villageId,
        countryId

        // for testing, available data
        // categoryId: 3,
        // brands: [170],
        // criterias: ['Kantor Cabang Pembantu'],
        // provinceId: 75,
        // cityId: undefined,
        // districtId: 22273,
        // villageId: undefined,
      });
      return { id, ...dataResponse };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const doAnalyzeDatexThematic = createAsyncThunk(
  'LI/mapbro/datex/do-analyze-thematic',
  /**
   * @description For do analyze datex thematic
   * @param {Object} param
   * @param {Number} param.id
   * @param {"red" | "blue" | "yellow"|"gray"|"random"} param.color
   * @param {Array<number>} param.attribute
   * @param {number} param.region
   * @param {number"province" | "regency" | "district" | "village"} param.levelData
   * @param {"national" | "province" | "regency" | "district"} param.levelRegion
   * @param {Number"} param.country_id
   */
  async (
    {
      id,
      color,
      attribute,
      region,
      levelData,
      levelRegion,
      country_id
    },
    { rejectWithValue }
  ) => {
    try {
      const dataResponse = await datexThematicService.AnalyzeDatexThematic({
        color,
        attribute,
        region,
        levelData,
        levelRegion,
        country_id
      });
      return { id, ...dataResponse };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getLegendColor = createAsyncThunk(
  'LI/mapbro/datex/legend-color',
  /**
   * @description For get legend color list
   */

  // eslint-disable-next-line no-empty-pattern
  async (_, { rejectWithValue }) => {
    try {
      const dataResponse = await datexService.GetLegendColor();
      return dataResponse;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
const doGetListDate = createAsyncThunk(
  'LI/mapbro/datex/do-get-list-date',
  async (param, { rejectWithValue }) => {
    try {
      const data = await datexService.GetListDate();
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
const doGetIndustryList = createAsyncThunk(
  'LI/mapbro/datex/do-get-industry-list',
  async (param, { rejectWithValue }) => {
    try {
      const data = await datexPOIService.GetIndustryList();
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const doGetIndustryListRinjani = createAsyncThunk(
  'LI/mapbro/datex/do-get-industry-list-rinjani',
  /**
   * @param {Object} param
   * @param {String|Number} param.countryId
   */
  async ({countryId}, { rejectWithValue }) => {
    try {
      const data = await datexPOIService.GetIndustryListRinjani(countryId);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const doGetIndustryDetail = createAsyncThunk(
  'LI/mapbro/datex/do-get-group-category-list',
  /**
   * @param {Object} param
   * @param {String|Number} param.id
   * @param {String|Number} param.countryId
   */
  async ({ id,countryId}, { rejectWithValue }) => {
    try {
      const data = await datexPOIService.GetGroupAndCategoryByIndustry({ id,countryId });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const doGetThematicCategoryList = createAsyncThunk(
  'LI/mapbro/datex/do-get-thematic-category-list',
  /**
   * @param {String} keyword
   */
  async (param, { rejectWithValue }) => {
    try {
      const data = await datexThematicService.GetThematicCategoryList(param);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const doGetThematicCategoryListRinjani = createAsyncThunk(
  'LI/mapbro/datex/do-get-thematic-category-list-rinjani',
  /**
   * @param {String} keyword
   */
  async (param, { rejectWithValue }) => {
    try {
      const {countryId,keyword} = param
      const data = await datexThematicService.GetThematicCategoryList(keyword,countryId);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const doGetThematicSubCategoryList = createAsyncThunk(
  'LI/mapbro/datex/do-get-thematic-subcategory-list',
  /**
   * @param {Object} param
   * @param {String|Number} param.id
   * @param {String} param.keyword
   */
  async ({ id, keyword }, { rejectWithValue }) => {
    try {
      const data = await datexThematicService.GetThematicSubCategoryList({
        id,
        keyword,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const doGetThematicSubCategoryListRinjani = createAsyncThunk(
  'LI/mapbro/datex/do-get-thematic-subcategory-list-rinjani',
  /**
   * @param {Object} param
   * @param {String|Number} param.id
   * @param {String} param.keyword
   * @param {String} param.countryId
   */
  async ({ id, keyword,countryId }, { rejectWithValue }) => {
    try {
      const data = await datexThematicService.GetThematicSubCategoryList({
        id,
        keyword,
        countryId
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const doGetThematicItemList = createAsyncThunk(
  'LI/mapbro/datex/do-get-thematic-item-list',
  /**
   * @param {Object} param
   * @param {String|Number} param.id
   * @param {String} param.keyword
   * @param {String} param.countryId
   */
  async ({ id, keyword,countryId }, { rejectWithValue }) => {
    try {
      const data = await datexThematicService.GetThematicItemList({
        id,
        keyword,
        countryId
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getBrands = createAsyncThunk(
  'LI/mapbro/datex/get-brands',
  async (query, { rejectWithValue }) => {
    try {
      const data = await datexPOIService.GetBrandV3(query);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export {
  doAnalyzeTelcoData,
  doAnalyzeDatexPoi,
  doAnalyzeDatexThematic,
  getLegendColor,
  doGetListDate,
  doGetIndustryList,
  doGetIndustryListRinjani,
  doGetIndustryDetail,
  doGetThematicCategoryList,
  doGetThematicCategoryListRinjani,
  doGetThematicSubCategoryList,
  doGetThematicSubCategoryListRinjani,
  doGetThematicItemList,
  getBrands,
};

import * as React from 'react';

const TesIcon = (props) => (
  <svg
    fill='none'
    height={26}
    width={26}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path d='M1 7a6 6 0 0 1 6-6h6v12H1V7Z' fill='#248E76' stroke='#CED4DA' />
    <path d='M13 1h6a6 6 0 0 1 6 6v6H13V1Z' fill='#89E1CD' stroke='#CED4DA' />
    <path
      d='M13 13h12v6a6 6 0 0 1-6 6h-6V13Z'
      fill='#2FB698'
      stroke='#CED4DA'
    />
    <path d='M1 13h12v12H7a6 6 0 0 1-6-6v-6Z' fill='#DAF6EF' stroke='#CED4DA' />
  </svg>
);

export default TesIcon;

/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
import { IconChevron } from '@bvt-assets/icon/icon-chevron';
import { IconInfo } from '@bvt-assets/icon/icon-info';
import { IconSearchTypeTwo } from '@bvt-assets/icon/icon-search-2';
import { CatchmentTypeRadio } from '@bvt-features/datex/component/CatchmentTypeRadio';
import { DatePicker, Form, Input, Radio, Slider, Switch } from 'antd';
import { useFormik } from 'formik';
const { RangePicker } = DatePicker;
import _, { debounce, isArray, isBoolean, isNumber, isString } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import './DatexTelcoStepOneTelco.scss';
import * as Yup from 'yup';
import moment from 'moment';
import { MONTH_LIST } from '@bvt-features/datex/constant/MONTH_LIST';
import { YEAR_LIST } from '@bvt-features/datex/constant/YEAR_LIST';
import IconClock from '@bvt-assets/icon/icon-clock';
import { SelectOption } from '@bvt-shared/component/SelectOption';

/**
 * @copyright PT Bhumi Varta Technology
 * @author Renta<renta.yustika@bvarta.com>
 */

/**
 * @description For list time type
 * @typedef TTelcoTimeType
 * @type {Object}
 * @property {string|number} key
 * @property {string} label
 */

/**
 * @description For list time type
 * @typedef TCatchmentType
 * @type {Object}
 * @property {string|number} key
 * @property {string} label
 * @property {any} icon
 */

/**
 * @description For list select object
 * @typedef TSelectObject
 * @type {Object}
 * @property {number} value
 * @property {string} label
 */

/**
 * @description For data poi object
 * @typedef TTelcoDataObject
 * @type {Object}
 * @property {number} province
 * @property {string} city
 * @property {'total'|'average'} catchmentType
 * @property {Array<string>} dateList
 * @property {''|'specific'|'popular'} timeType
 * @property {Array<number>} timeList
 * @property {boolean} showCalendar
 * @property {Number} year
 * @property {Number} month
 */

/**
 * @param {object} props
 * @param {TTelcoDataObject} props.data
 * @param {(search:string)=>void} props.onSearchProvince
 * @param {TSelectObject} props.provinceList
 * @param {boolean} props.loadingProvince
 * @param {async(search:string,id_province:number)=>void} props.onSearchCity
 * @param {TCityTSelectObjectObject} props.cityList
 * @param {boolean} props.loadingCity
 * @param {(valid:boolean)=>void} props.onValidOrInvalid
 * @param {Array<TCatchmentType>} props.catchmentTypeList
 * @param {Array<TSelectObject>} props.timeTypeList
 * @param {Array<TSelectObject>} props.popularTimeList
 * @param {(data:TTelcoDataObject)=>void} props.onChange
 * @param {object} props.unavailableDate
 */

export const DatexTelcoStepOneTelco = (props) => {
  const {
    data,
    onSearchProvince,
    provinceList,
    onSearchCity,
    cityList,
    onValidOrInvalid,
    loadingCity,
    loadingProvince,
    catchmentTypeList,
    timeTypeList,
    popularTimeList,
    onChange,
    unavailableDate,
  } = props;
  const [state, setState] = useState({
    year: null,
    month: null,
    disabledDate: null,
    tempDate: undefined,
  });

  const telcoFormik = useFormik({
    initialValues: {
      province: undefined,
      city: undefined,
      catchmentType: undefined,
      showCalendar: false,
      dateList: undefined,
      timeType: '',
      timeList: undefined,
      year: undefined,
      month: undefined,
    },
    validateOnMount: true,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: Yup.object().shape({
      province: Yup.number().required(),
      city: Yup.string().required(),
      catchmentType: Yup.string().required(),
      showCalendar: Yup.boolean().required(),
      dateList: Yup.array(),
      timeType: Yup.mixed(),
      timeList: Yup.array(),
      year: Yup.number().required(),
      month: Yup.number().required(),
    }),
  });

  useEffect(async () => {
    if (isNumber(data?.province) && isString(data?.city)) {
      await telcoFormik.setFieldValue('province', data?.province);
      onSearchCity && (await onSearchCity('', data?.province));
      await telcoFormik.setFieldValue('city', data?.city);
    }
    if (isNumber(data?.year) && isNumber(data?.month)) {
      setState({ ...state, year: data?.year, month: data?.month });
      telcoFormik.setFieldValue('year', data?.year);
      telcoFormik.setFieldValue('month', data?.month);
    }
    if (_.includes(['total', 'average'], data?.catchmentType)) {
      telcoFormik.setFieldValue('catchmentType', data?.catchmentType);
    }
    if (isBoolean(data?.showCalendar)) {
      telcoFormik.setFieldValue('showCalendar', data?.showCalendar);
    }
    if (isArray(data?.dateList)) {
      telcoFormik.setFieldValue('dateList', data?.dateList);
    } else {
      await internalHandleYearMonth({ year: data?.year, month: data?.month });
    }
    if (isArray(data?.timeList)) {
      telcoFormik.setFieldValue('timeList', data?.timeList);
    }

    checkIfSpecificPopular(data, telcoFormik, onChange);
  }, [data]);

  useEffect(() => {
    if (Object.keys(telcoFormik?.errors)?.length > 0) {
      onValidOrInvalid && onValidOrInvalid(false);
    } else if (
      telcoFormik.values?.timeType !== '' &&
      telcoFormik.values?.timeList?.length < 1
    ) {
      onValidOrInvalid && onValidOrInvalid(false);
    } else {
      onValidOrInvalid && onValidOrInvalid(true);
    }
  }, [telcoFormik]);

  const internalDebouncedSearchProvince = useCallback(
    debounce(onSearchProvince, 300),
    []
  );

  const internalDebouncedSearchCity = useCallback(
    debounce(onSearchCity, 300),
    []
  );

  /**
   *
   * @param {object} param
   * @param {number} param.year
   * @param {number} param.month
   */
  const internalHandleYearMonth = async (param) => {
    if (param?.year && param?.month) {
      const firstDateOfMonth = moment(
        `${param?.year}-${param?.month}-01`,
        'YYYY-MM-DD'
      ).format('YYYY-MM-DD');
      const lastDateOfMonth = moment(firstDateOfMonth)
        ?.endOf('month')
        .format('YYYY-MM-DD');
      const arrOfDates = internalHandleRangeDates({
        start: firstDateOfMonth,
        end: lastDateOfMonth,
      });
      onChange &&
        onChange({
          dateList: arrOfDates,
          year: param?.year,
          month: param?.month,
        });
      setState({
        ...state,
        year: param?.year,
        month: param?.month,
      });
    }
  };

  /**
   *
   * @param {object} param
   * @param {string} param.start
   * @param {string} param.end
   */
  const internalHandleRangeDates = (param) => {
    let arrOfDates = [];
    let tempStart = param?.start;
    while (moment(tempStart)?.diff(moment(param?.end), 'days') <= 0) {
      arrOfDates?.push(moment(tempStart)?.clone()?.format('YYYY-MM-DD'));
      tempStart = moment(tempStart)?.add(1, 'day');
    }
    return arrOfDates;
  };

  /**
   *
   * @param {object} param
   * @param {string} param.dateStart
   * @param {string} param.dateEnd
   */
  const internalHandleCalendar = (param) => {
    if (param?.dateStart && param?.dateEnd) {
      setState({ ...state, disabledDate: null });
    } else if (!param?.dateEnd) {
      const prevDates = moment(param?.dateStart)?.subtract(6, 'days');
      const nextDates = moment(param?.dateStart)?.add(6, 'days');
      setState({
        ...state,
        disabledDate: {
          prevDates: prevDates,
          nextDates: nextDates,
        },
      });
    }
  };
  /**
   * @param {Array<number>} listOfTime
   */
  const internalHandleChangeTime = (listOfTime) => {
    const mappedTime = _.map(listOfTime, (v) => {
      const time = v > 9 ? v : `0${v}`;
      return `${time}:00`;
    });
    onChange &&
      onChange({
        timeList: mappedTime,
      });
  };

  /**
   * @param {Array<string>} listOfDates
   */
  const internalHandleOnChangeRangePicker = (listOfDates) => {
    const arrOfDates = internalHandleRangeDates({
      start: listOfDates?.[0],
      end: listOfDates?.[1],
    });
    onChange &&
      onChange({
        dateList: arrOfDates,
      });
  };

  let displayTime = '01:00';
  const timeInt = parseInt(
    telcoFormik.values?.timeList?.[0]?.replace(':00', '')
  );
  if (telcoFormik.values?.timeList?.length > 0) {
    if (timeInt > 9) {
      displayTime = `${timeInt}:00`;
    } else {
      displayTime = `0${timeInt}:00`;
    }
  }

  return (
    <div className='DatexTelcoStepOneTelco__container'>
      <div className='DatexTelcoStepOneTelco__search'>
        <span>Select province and city/regency you want to explore.</span>
        <SelectOption
          dropdownRender={(menu) => (
            <React.Fragment>
              <Input
                className='DatexTelcoStepOneTelco__select--search'
                onChange={(e) => {
                  internalDebouncedSearchProvince(e.target.value);
                }}
                placeholder='Search'
                suffix={<IconSearchTypeTwo />}
              />
              {menu}
            </React.Fragment>
          )}
          loading={loadingProvince}
          onChange={(e) => {
            onChange &&
              onChange({
                province: e,
                cityId: undefined,
              });
            internalDebouncedSearchCity('', e);
          }}
          options={provinceList || []}
          placeholder='Select Province'
          suffixIcon={<IconChevron />}
          value={_.find(
            provinceList,
            (v) => v?.value === telcoFormik.values?.province
          )}
        />
        <SelectOption
          disabled={!telcoFormik.values?.province}
          dropdownRender={(menu) => (
            <React.Fragment>
              <Input
                className='DatexTelcoStepOneTelco__select--search'
                onChange={(e) => {
                  internalDebouncedSearchCity(
                    e.target.value,
                    telcoFormik.values?.province
                  );
                }}
                placeholder='Search'
                suffix={<IconSearchTypeTwo />}
              />
              {menu}
            </React.Fragment>
          )}
          loading={loadingCity}
          onChange={(e) => {
            onChange &&
              onChange({
                province: telcoFormik.values?.province,
                city: e,
                cityId: _.find(cityList, (v) => v?.value === e)?.city_id,
              });
          }}
          options={cityList || []}
          placeholder='Select City/Regency'
          suffixIcon={<IconChevron />}
          value={_.find(cityList, (v) => v?.value === telcoFormik.values?.city)}
        />
      </div>
      <div className='DatexTelcoStepOneTelco__date-time'>
        <span>Year and Month</span>
        <div className='DatexTelcoStepOneTelco__date-time--grid'>
          <SelectOption
            onChange={(e) => {
              setState({ ...state, year: e });
              internalHandleYearMonth({ month: state?.month, year: e });
            }}
            options={YEAR_LIST || []}
            placeholder='Year'
            suffixIcon={<IconChevron />}
            value={state?.year}
          />
          <SelectOption
            disabled={!state?.year}
            onChange={(e) => {
              setState({ ...state, month: e });
              internalHandleYearMonth({ year: state?.year, month: e });
            }}
            options={MONTH_LIST || []}
            placeholder='Month'
            suffixIcon={<IconChevron />}
            value={state?.month}
          />
        </div>
        <span>Data Catchment Type</span>
        <div className='DatexTelcoStepOneTelco__date-time--catchment-type'>
          <CatchmentTypeRadio
            activeKey={telcoFormik.values?.catchmentType}
            data={catchmentTypeList || []}
            onChange={(e) => {
              onChange &&
                onChange({
                  catchmentType: e?.key,
                  showCalendar: false,
                  timeType: '',
                  timeList: [],
                  dateList: undefined,
                });
            }}
          />
          <div className='DatexTelcoStepOneTelco__date-time--catchment-type--switch'>
            <span>Set by date</span>
            <Switch
              checked={telcoFormik?.values?.showCalendar}
              disabled={
                telcoFormik.values?.catchmentType !== 'total' || !state?.month
              }
              onChange={(e) => {
                onChange &&
                  onChange({
                    showCalendar: e,
                    timeType: '',
                    timeList: [],
                    dateList: undefined,
                  });
              }}
            />
          </div>
        </div>
        <div className='DatexTelcoStepOneTelco__date-time--catchment-info'>
          <span>Total device at area in a month</span>
          <IconInfo />
        </div>
        {telcoFormik.values?.showCalendar && (
          <React.Fragment>
            <div className='DatexTelcoStepOneTelco__date-time--catchment-calendar'>
              <span className='maximum-info'>
                Maximum date range is 7 days in a month
              </span>
              <RangePicker
                className='range-picker'
                disabledDate={(current) => {
                  const startDate = moment('2020-01-01');
                  return (
                    current < startDate ||
                    current > state?.disabledDate?.nextDates ||
                    _.includes(
                      unavailableDate,
                      moment(current)?.format('YYYY-MM-DD')
                    )
                  );
                }}
                format='YYYY-MM-DD'
                getPopupContainer={(e) => {
                  let popupDiv = document.createElement('div');
                  popupDiv.className =
                    'DatexTelcoStepOneTelco__date-time--catchment-calendar--wrapper';
                  e.parentElement.append(popupDiv);
                  return popupDiv;
                }}
                onCalendarChange={(e) =>
                  internalHandleCalendar({
                    dateStart: e?.[0],
                    dateEnd: e?.[1],
                  })
                }
                onChange={(e) => internalHandleOnChangeRangePicker(e)}
                open={true}
                value={
                  telcoFormik.values?.dateList
                    ? [
                        moment(telcoFormik.values?.dateList?.[0]),
                        moment(
                          telcoFormik.values?.dateList?.[
                            telcoFormik.values?.dateList?.length - 1
                          ]
                        ),
                      ]
                    : null
                }
              />
            </div>
          </React.Fragment>
        )}
        <div style={{ display: 'none' }}>
          <span>Time Type</span>
          <SelectOption
            allowClear
            disabled={
              telcoFormik?.values?.dateList?.length !== 1 ||
              telcoFormik?.values?.catchmentType !== 'total'
            }
            dropdownRender={(menu) => <React.Fragment>{menu}</React.Fragment>}
            labelInValue
            onChange={(e) => {
              onChange &&
                onChange({
                  timeType: e?.value,
                  timeList: [],
                });
            }}
            onClear={() => {
              onChange &&
                onChange({
                  timeType: '',
                });
            }}
            options={timeTypeList || []}
            placeholder='Select Time Type'
            suffixIcon={<IconChevron />}
            value={_.find(
              timeTypeList,
              (v) => v?.value === telcoFormik.values?.timeType
            )}
          />
          {telcoFormik.values?.timeType === 'popular' && (
            <React.Fragment>
              {/* <Radio.Group
                name='radioGroup-timeType'
              > */}
              <Form layout='vertical'>
                <Form.Item name='radioGroup-timeType'>
                  {_.map(popularTimeList, (v, idx) => {
                    return (
                      <Radio
                        checked={_.isEqual(
                          v?.value,
                          _.map(telcoFormik.values?.timeList, (a) => {
                            return parseInt(a?.replace(':00', ''));
                          })
                        )}
                        key={idx}
                        onChange={() => {
                          internalHandleChangeTime(v.value);
                        }}
                      >
                        {v?.label}
                      </Radio>
                    );
                  })}
                </Form.Item>
              </Form>
              {/* </Radio.Group> */}
            </React.Fragment>
          )}
          {telcoFormik.values?.timeType === 'specific' && (
            <React.Fragment>
              <div className='DatexTelcoStepOneTelco__date-time--slider'>
                <div>
                  <IconClock />
                  {displayTime}
                </div>
              </div>
              <Slider
                max={23}
                min={1}
                onChange={(e) => {
                  internalHandleChangeTime([e]);
                }}
                tooltip={{ formatter: null }}
                value={telcoFormik.values?.timeList?.length > 0 ? timeInt : 1}
              />
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

const checkIfSpecificPopular = (data, telcoFormik, onChange) => {
  if (_.includes(['', 'specific', 'popular'], data?.timeType)) {
    if (data.timeType !== '') {
      telcoFormik.setFieldValue('showCalendar', true);
    }
    telcoFormik.setFieldValue('timeType', data?.timeType);
    if (data.timeType === 'specific' && data?.timeList?.length < 1) {
      onChange({
        timeList: ['01:00'],
      });
    }
  }
};

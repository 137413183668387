import { combineReducers } from 'redux';
import { MAINMAP_MAP_MENUBAR_REDUCER } from '../menu-bar/redux';
import { MAINMAP_PLACE_REDUCER } from '../place/redux';
import { MAINMAP_MAP_MODULEBAR_REDUCER } from '../module-bar/redux';
import { MAIN_MAP_REDUCER } from '../mainmap/store/mainmap.slice';
import { DROPPABLE_MARKER_REDUCER } from '../droppable-marker/store/droppable_marker.slice';
import { LEGEND_REDUCER } from '@bvt-features/mainmap/legend/store/legend.slice';

export const MAINMAP_REDUCER = combineReducers({
  PLACE: MAINMAP_PLACE_REDUCER,
  MENUBAR: MAINMAP_MAP_MENUBAR_REDUCER,
  MODULEBAR: MAINMAP_MAP_MODULEBAR_REDUCER,
  DROPPABLE_MARKER: DROPPABLE_MARKER_REDUCER,
  LEGEND: LEGEND_REDUCER,
  MAINMAP: MAIN_MAP_REDUCER,
});

import React, {
  Fragment,
  useState,
  useLayoutEffect,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { usePlace } from '../../hooks/usePlace';
import { useMap } from 'react-leaflet';
import { debounce, isEmpty } from 'lodash';
import MapAdvancedSearch from '../MapAdvancedSearch/MapAdvancedSearch';
import { MapSearchTag } from '../MapSearchTag/MapSearchTag';
import './MapSearch.scss';
import { ID_SOURCE_CONSTANT } from '@bvt-shared/constant/ID_SOURCE_CONSTANT';
import { useMainMap } from '@bvt-features/mainmap/mainmap/hook/useMainMap';

const IconSearchTemporary = () => {
  return (
    <svg
      className='search'
      height='1rem'
      viewBox='0 0 15.757 16.145'
      width='1rem'
    >
      <path
        d='M9.288,0A6.9,6.9,0,0,0,3.45,10.585l-.015.006-3,3,2.551,2.551,3.111-3.112,0-.013A6.9,6.9,0,1,0,9.288,0Zm0,3.066A3.835,3.835,0,1,1,5.453,6.9,3.835,3.835,0,0,1,9.288,3.066Z'
        fill='currentColor'
        id='search'
        transform='translate(-0.432)'
      />
    </svg>
  );
};

const idVillage = (LIST_SEARCH) => {
  return !isEmpty(LIST_SEARCH.desa) ? LIST_SEARCH.desa.id : '';
};

const idDistrict = (LIST_SEARCH) => {
  return !isEmpty(LIST_SEARCH.kecamatan) ? LIST_SEARCH.kecamatan.id : '';
};

const idCity = (LIST_SEARCH) => {
  return !isEmpty(LIST_SEARCH.kota) ? LIST_SEARCH.kota.id : '';
};

const idProvince = (LIST_SEARCH) => {
  return !isEmpty(LIST_SEARCH.provinsi) ? LIST_SEARCH.provinsi.id : '';
};

export function MapSearch() {
  const [page, setPage] = useState(1);
  const [options, setOptions] = useState(null);
  const [input, setInput] = useState('');
  const [scroll, setScroll] = useState(false);
  const [advanced, isAdvanced] = useState(false);
  const [show, isShow] = useState(false);
  const [indexInput, isIndexInput] = useState(0);
  const initialMap = useMap();
  const mapp = useMainMap();

  const prevInputRef = useRef();
  useEffect(() => {
    prevInputRef.current = indexInput;
  }, [indexInput]);

  const PLACE = usePlace();

  const LIST_DATA = PLACE.state.getall.list;
  const LIST_SEARCH = PLACE.state.search.list;
  const LIST_PARAMS = PLACE.state.params.provinsi;

  useEffect(() => {
    return () => {
      PLACE.resetAll();
    };
  }, []);

  useLayoutEffect(() => {
    if (scroll === true) {
      if (!isEmpty(LIST_DATA) && !isEmpty(input)) {
        if (prevInputRef.current !== indexInput) {
          setOptions(null);
          setScroll(false);
        } else {
          setOptions([...options, ...LIST_DATA]);
        }
      }
    } else if (scroll === false) {
      setOptions(LIST_DATA);
    }

    if (!isEmpty(PLACE.state.getall.message)) {
      PLACE.state.getall.message === 'No records found' && setOptions(null);
    }
  }, [LIST_DATA, indexInput, scroll]);

  useEffect(() => {
    if (advanced === true) {
      initialMap.dragging.disable();
      initialMap.doubleClickZoom.disable();
      initialMap.scrollWheelZoom.disable();
    } else {
      initialMap.dragging.enable();
      initialMap.doubleClickZoom.enable();
      initialMap.scrollWheelZoom.enable();
    }
    !isEmpty(LIST_PARAMS) ? void 0 : setInput('');
  }, [advanced, LIST_PARAMS]);

  useLayoutEffect(() => {
    if (scroll === false) {
      if (show === true && prevInputRef.current !== indexInput) {
        getData(input);
      }
      if (!isEmpty(PLACE.state.getall) && !isEmpty(input)) {
        setOptions(LIST_DATA);
      }
    }
    isIndexInput(input.length);
    input === '' && isShow(false);
    if (show === true) {
      initialMap.dragging.disable();
      initialMap.doubleClickZoom.disable();
      initialMap.scrollWheelZoom.disable();
    } else {
      initialMap.dragging.enable();
      initialMap.doubleClickZoom.enable();
      initialMap.scrollWheelZoom.enable();
    }
  }, [input, options, show, indexInput, scroll]);

  const getData = useCallback(
    debounce((value) => {
      const params = {
        meta: { filter: { keyword: value }, page: page },
      };
      PLACE.get_all({ params });
    }, 500),
    []
  );

  const loadMoreResults = () => {
    if (options?.length < PLACE.state.getall.properties.total) {
      const nextPage = page + 1;
      setPage(nextPage);
      const params = {
        meta: { filter: { keyword: input }, page: nextPage },
      };
      PLACE.get_all({ params });
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  const handleScroll = (event) => {
    const listboxNode = event.currentTarget;
    const position = listboxNode.scrollTop + listboxNode.clientHeight;
    if (listboxNode.scrollHeight - position <= 1) {
      loadMoreResults();
    }
  };

  const onSelectItemList = (e, value) => {
    PLACE.setGeojson({ provinsi: null });
    PLACE.setGeojson({ kota: null });
    PLACE.setGeojson({ kecamatan: null });
    PLACE.setGeojson({ desa: null });
    const params = {
      meta: { filter: { id: value.id, type: value.type } },
    };
    PLACE.get_search({ params });
    setInput(e.target.innerText);
    isShow(false);
  };

  useEffect(() => {
    if (!isEmpty(LIST_SEARCH)) {
      LIST_SEARCH.provinsi !== null
        ? PLACE.setParams({
          type: 'provinsi',
          page: 1,
          id_provinsi: LIST_SEARCH.provinsi.id,
          kode_provinsi: LIST_SEARCH.provinsi.code,
          nama_provinsi: LIST_SEARCH.provinsi.name,
        })
        : void 0;
      LIST_SEARCH.kota !== null
        ? PLACE.setParams({
          type: 'kota',
          page: 1,
          id_kota: LIST_SEARCH.kota.id,
          id_provinsi: LIST_SEARCH.provinsi.id,
          kode_kota: LIST_SEARCH.kota.code,
          nama_kota: LIST_SEARCH.kota.name,
        })
        : void 0;
      LIST_SEARCH.kecamatan !== null
        ? PLACE.setParams({
          type: 'kecamatan',
          page: 1,
          id_kecamatan: LIST_SEARCH.kecamatan.id,
          kode_kecamatan: LIST_SEARCH.kecamatan.code,
          nama_kecamatan: LIST_SEARCH.kecamatan.name,
          id_kota: LIST_SEARCH.kota.id,
        })
        : void 0;
      LIST_SEARCH.desa !== null
        ? PLACE.setParams({
          type: 'desa',
          page: 1,
          id_desa: LIST_SEARCH.desa.id,
          id_kecamatan: LIST_SEARCH.kecamatan.id,
          kode_desa: LIST_SEARCH.desa.code,
          nama_desa: LIST_SEARCH.desa.name,
        })
        : void 0;
      const params = {
        meta: {
          filter: {
            id_province: idProvince(LIST_SEARCH),
            id_city: idCity(LIST_SEARCH),
            id_district: idDistrict(LIST_SEARCH),
            id_village: idVillage(LIST_SEARCH),
            id_source: ID_SOURCE_CONSTANT,
          },
        },
      };
      getAdvanced(params);
    }
  }, [LIST_SEARCH]);

  const getAdvanced = (params) => {
    PLACE.get_advance({ params });
  };

  return (
    <Fragment>
      {advanced && <MapAdvancedSearch advanced={advanced} isAdvanced={isAdvanced} /> }

      <div
        className='bvt-mapsearch'
        style={{
          right: `${mapp.state.activeMenu ? mapp.state.activeMenuWidth + 'px' : '0.5rem'}`,
        }}
      >
        <MapSearchTag />
        <div className='bvt-mapsearch__card'>
          {PLACE.state.country_mandala.country_id == 1 &&
            <Fragment>
            <input
              className='bvt-mapsearch__input'
              disabled={PLACE.state.isAdvanceSearchDisabled}
              onAuxClickCapture={() => isShow(false)}
              onChange={(e) => {
                setInput(e.target.value.toLowerCase());
                isShow(true);
              }}
              onFocus={() => isShow(true)}
              placeholder='Search'
              type='text'
              value={input}
            />
            <IconSearchTemporary />
            </Fragment>
              
          }       
          <button
            className='bvt-mapsearch__button'
            disabled={PLACE.state.isAdvanceSearchDisabled}
            onClick={() => isAdvanced(!advanced)}
          >
            Advanced
          </button>

          {show && (
            <Fragment>
              {!isEmpty(input) && (
                <div
                  className={
                    !isEmpty(input)
                      ? 'bvt-mapsearch__list'
                      : 'bvt-mapsearch__list:active'
                  }
                  onMouseLeave={() => isShow(false)}
                  onScroll={handleScroll}
                >
                  <Fragment>
                    {!isEmpty(options) ? (
                      options.map((item, index) => {
                        const idx = index + 1;
                        return (
                          <div
                            className='bvt-mapsearch__list__item'
                            key={idx}
                            onClick={(e) =>
                              onSelectItemList(e, {
                                id: item.id,
                                type: item.type,
                              })
                            }
                          >
                            {item.displayname}
                          </div>
                        );
                      })
                    ) : (
                      <div className='bvt-mapsearch__list__notfound'>
                        No records found
                      </div>
                    )}
                  </Fragment>
                </div>
              )}
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
}

import { MAINMAP_PLACE_CONSTANT } from '@bvt-features/mainmap/place/redux';
import { MainMapPlaceService } from '../service';

const mainMapPlaceService = new MainMapPlaceService();


export const MAINMAP_PLACE_ACTIONS = {
  get_all: (query) => {
    return async (dispatch) => {
      dispatch({
        type: MAINMAP_PLACE_CONSTANT.GET_START,
        payload: {
          getall: {
            status_GET: 'LOADING',
          },
        },
      });
      try {
        const data = await mainMapPlaceService.get_all(query);
        dispatch({
          type: MAINMAP_PLACE_CONSTANT.GET_SUCCESS,
          payload: {
            getall: {
              status_GET: 'SUCCESS',
              list: data.results,
              message: data.message,
              properties: data.meta,
            },
          },
        });
      } catch (error) {
        dispatch({
          type: MAINMAP_PLACE_CONSTANT.GET_FAILED,
          payload: {
            getall: {
              status_GET: 'FAILED',
              message: error.message,
            },
          },
        });
      }
    };
  },
  get_country:(query) => {
    return async (dispatch) => {
      dispatch({
        type: MAINMAP_PLACE_CONSTANT.GET_COUNTRY_START,
        payload: {
          country: {
            status_GET: 'LOADING',
          },
        },
      });
      try {
        const data = await mainMapPlaceService.get_country(query);
        dispatch({
          type: MAINMAP_PLACE_CONSTANT.GET_COUNTRY_SUCCESS,
          payload: {
            country: {
              status_GET: 'SUCCESS',
              list: data.results,
              message: data.message,
              properties: data.meta,
            },
          },
        });
      } catch (error) {
        dispatch({
          type: MAINMAP_PLACE_CONSTANT.GET_COUNTRY_FAILED,
          payload: {
            country: {
              status_GET: 'FAILED',
              message: error.message,
            }
          },
        });
      }
    };
  },
  get_province_inter:(query) => {
    return async (dispatch) => {
      dispatch({
        type: MAINMAP_PLACE_CONSTANT.GET_PROVINCE_INTER_START,
        payload: {
          province: {
            status_GET: 'LOADING',
          },
        },
      });
      try {
        const data = await mainMapPlaceService.get_province_inter(query);
        dispatch({
          type: MAINMAP_PLACE_CONSTANT.GET_PROVINCE_INTER_SUCCESS,
          payload: {
            province: {
              status_GET: 'SUCCESS',
              list: data.results,
              message: data.message,
              properties: data.meta,
            },
          },
        });
      } catch (error) {
        dispatch({
          type: MAINMAP_PLACE_CONSTANT.GET_PROVINCE_INTER_FAILED,
          payload: {
            province: {
              status_GET: 'FAILED',
              message: error.message,
            }
          },
        });
      }
    };
  },
  get_city_inter:(query) => {
    return async (dispatch) => {
      dispatch({
        type: MAINMAP_PLACE_CONSTANT.GET_CITY_INTER_START,
        payload: {
          city: {
            status_GET: 'LOADING',
          },
        },
      });
      try {
        const data = await mainMapPlaceService.get_city_inter(query);
        dispatch({
          type: MAINMAP_PLACE_CONSTANT.GET_CITY_INTER_SUCCESS,
          payload: {
            city: {
              status_GET: 'SUCCESS',
              list: data.results,
              message: data.message,
              properties: data.meta,
            },
          },
        });
      } catch (error) {
        dispatch({
          type: MAINMAP_PLACE_CONSTANT.GET_CITY_INTER_FAILED,
          payload: {
            city: {
              status_GET: 'FAILED',
              message: error.message,
            }
          },
        });
      }
    };
  },
  get_district_inter:(query) => {
    return async (dispatch) => {
      dispatch({
        type: MAINMAP_PLACE_CONSTANT.GET_DISTRICT_INTER_START,
        payload: {
          district: {
            status_GET: 'LOADING',
          },
        },
      });
      try {
        const data = await mainMapPlaceService.get_district_Inter(query);
        dispatch({
          type: MAINMAP_PLACE_CONSTANT.GET_DISTRICT_INTER_SUCCESS,
          payload: {
            district: {
              status_GET: 'SUCCESS',
              list: data.results,
              message: data.message,
              properties: data.meta,
            },
          },
        });
      } catch (error) {
        dispatch({
          type: MAINMAP_PLACE_CONSTANT.GET_DISTRICT_INTER_FAILED,
          payload: {
            district: {
              status_GET: 'FAILED',
              message: error.message,
            }
          },
        });
      }
    };
  },
  get_province: (query) => {
    return async (dispatch) => {
      dispatch({
        type: MAINMAP_PLACE_CONSTANT.GET_PROVINCE_START,
        payload: {
          province: {
            status_GET: 'LOADING',
          },
        },
      });
      try {
        const data = await mainMapPlaceService.get_province(query);
        dispatch({
          type: MAINMAP_PLACE_CONSTANT.GET_PROVINCE_SUCCESS,
          payload: {
            province: {
              status_GET: 'SUCCESS',
              list: data.results,
              message: data.message
            },
          },
        });
      } catch (error) {
        dispatch({
          type: MAINMAP_PLACE_CONSTANT.GET_PROVINCE_FAILED,
          payload: {
            province: {
              status_GET: 'FAILED',
              message: error.message,
            }
          },
        });
      }
    };
  },
  get_city: (query) => {
    return async (dispatch) => {
      dispatch({
        type: MAINMAP_PLACE_CONSTANT.GET_CITY_START,
        payload: {
          city: {
            status_GET: 'LOADING',
          },
          city_list_datex: {
            status_GET: 'LOADING',
          }
        },
      });
      try {
        const data = await mainMapPlaceService.get_city(query);
        dispatch({
          type: MAINMAP_PLACE_CONSTANT.GET_CITY_SUCCESS,
          payload: {
            city: {
              status_GET: 'SUCCESS',
              list: data.results,
              message: data.message,
              properties: data.meta,
            },
            city_list_datex: {
              status_GET: 'SUCCESS',
              list: data.results,
              message: data.message,
              properties: data.meta,
            }
          },
        });
      } catch (error) {
        dispatch({
          type: MAINMAP_PLACE_CONSTANT.GET_CITY_FAILED,
          payload: {
            city: {
              status_GET: 'FAILED',
              message: error.message,
            }
          },
        });
      }
    };
  },
  get_district: (query) => {
    return async (dispatch) => {
      dispatch({
        type: MAINMAP_PLACE_CONSTANT.GET_DISTRICT_START,
        payload: {
          district: {
            status_GET: 'LOADING',
          },
        },
      });
      try {
        const data = await mainMapPlaceService.get_district(query);
        dispatch({
          type: MAINMAP_PLACE_CONSTANT.GET_DISTRICT_SUCCESS,

          payload: {
            district: {
              status_GET: 'SUCCESS',
              list: data.results,
              message: data.message,
              properties: data.meta,
            },
          },
        });
      } catch (error) {
        dispatch({
          type: MAINMAP_PLACE_CONSTANT.GET_DISTRICT_FAILED,
          payload: {
            district: {
              status_GET: 'FAILED',
              message: error.message,
            }
          },
        });
      }
    };
  },
  get_village: (query) => {
    return async (dispatch) => {
      dispatch({
        type: MAINMAP_PLACE_CONSTANT.GET_VILLAGE_START,
        payload: {
          village: {
            status_GET: 'LOADING',
          },
        },
      });
      try {
        const data = await mainMapPlaceService.get_village(query);
        dispatch({
          type: MAINMAP_PLACE_CONSTANT.GET_VILLAGE_SUCCESS,
          payload: {
            village: {
              status_GET: 'SUCCESS',
              list: data.results,
              message: data.message,
              properties: data.meta,
            },
          },
        });
      } catch (error) {
        dispatch({
          type: MAINMAP_PLACE_CONSTANT.GET_VILLAGE_FAILED,
          payload: {
            village: {
              status_GET: 'FAILED',
              message: error.message,
            }
          },
        });
      }
    };
  },
  get_advance: (query) => {
    return async (dispatch) => {
      dispatch({
        type: MAINMAP_PLACE_CONSTANT.GET_ADVANCE_START,
        payload: {
          advance: {
            status_GET: 'LOADING'
          }
        },
      });
      try {
        const data = await mainMapPlaceService.get_advance(query);
        dispatch({
          type: MAINMAP_PLACE_CONSTANT.GET_ADVANCE_SUCCESS,
          payload: {
            advance: {
              status_GET: 'SUCCESS',
              list: data.result,
              message: data.message,
              properties: data.meta,
            },
          },
        });
      } catch (error) {
        dispatch({
          type: MAINMAP_PLACE_CONSTANT.GET_ADVANCE_FAILED,
          payload: {
            advance: {
              status_GET: 'FAILED',
              message: error.message,
            }
          },
        });
      }
    };
  },
  get_advance_inter: (query) => {
    return async (dispatch) => {
      dispatch({
        type: MAINMAP_PLACE_CONSTANT.GET_ADVANCE_INTER_START,
        payload: {
          advance: {
            status_GET: 'LOADING'
          }
        },
      });
      try {
        const countryId = query.params.meta.filter.country_id
        const payload = {
          polygon_provinsi:{},
          polygon_kota:{},
          polygon_kecamatan:{}
        }

        const processFilter = async (filterKey, polygonKey) => {
          if (query.params.meta.filter.hasOwnProperty(filterKey)) {
            const data = await mainMapPlaceService.get_advance_inter(query);
            delete query.params.meta.filter[filterKey];
            payload[polygonKey] = {
              type: "Feature",
              geometry: data.result.geom,
              properties: {
                displayName: data.result.name,
                kode:data.result.code,
                id:data.result.id
              }
            };
          }
        };

        if (countryId == 2) {
          await processFilter("district_id", "polygon_kecamatan");
          await processFilter("prefecture_id", "polygon_kota");
        }

        if (countryId == 3) {
          await processFilter("commune_id", "polygon_kecamatan");
          await processFilter("district_id", "polygon_kota");
        }

        const data = await mainMapPlaceService.get_advance_inter(query);

        payload["polygon_provinsi"] = {
          type:"Feature",
          geometry:data.result.geom,
          properties:{
            displayName:data.result.name,
            kode:data.result.code,
            id:data.result.id
          }
        }

        dispatch({
          type: MAINMAP_PLACE_CONSTANT.GET_ADVANCE_SUCCESS,
          payload: {
            advance: {
              status_GET: 'SUCCESS',
              list: payload,
              message: data.message,
              properties: data.meta,
            },
          },
        });
      } catch (error) {
        dispatch({
          type: MAINMAP_PLACE_CONSTANT.GET_ADVANCE_INTER_FAILED,
          payload: {
            advance: {
              status_GET: 'FAILED',
              message: error.message,
            }
          },
        });
      }
    };
  },
  get_coordinates: (query) => {
    return async (dispatch) => {
      dispatch({
        type: MAINMAP_PLACE_CONSTANT.GET_COORDINATES_START,
        payload: { status_GET: 'LOADING' },
      });
      try {
        const data = await mainMapPlaceService.get_coordinates(query);
        dispatch({
          type: MAINMAP_PLACE_CONSTANT.GET_COORDINATES_SUCCESS,
          payload: {
            status_GET: 'SUCCESS',
            coordinateDetail: data.result,
            message: data.message,
            properties: data.meta,
            detail: [],
          },
        });
      } catch (error) {
        dispatch({
          type: MAINMAP_PLACE_CONSTANT.GET_COORDINATES_FAILED,
          payload: {
            status_GET: 'FAILED',
            message: error.message,
          },
        });
      }
    };
  },
  get_search: (query) => {
    return async (dispatch) => {
      dispatch({
        type: MAINMAP_PLACE_CONSTANT.GET_SEARCH_START,
        payload: { status_GET: 'LOADING' },
      });
      try {
        const data = await mainMapPlaceService.get_search(query);
        dispatch({
          type: MAINMAP_PLACE_CONSTANT.GET_SEARCH_SUCCESS,
          payload: {
            search: {
              status_GET: 'SUCCESS',
              list: data.result,
              message: data.message,
              properties: data.meta,
            },
          },
        });
      } catch (error) {
        dispatch({
          type: MAINMAP_PLACE_CONSTANT.GET_SEARCH_FAILED,
          payload: {
            search: {
              status_GET: 'FAILED',
              message: error.message,
            },
          },
        });
      }
    };
  },
  /**
   * @param {boolean} param
   */
  setAdvanceSearchDisabled: (param) => {
    return (dispatch) => {
      dispatch({
        type: MAINMAP_PLACE_CONSTANT.SET_SEARCH_LOCATION_DISABLED,
        payload: { isAdvanceSearchDisabled: param },
      });
    };
  },
  setChangeCountryDisable: (param) => {
    return (dispatch) => {
      dispatch({
        type: MAINMAP_PLACE_CONSTANT.SET_SEARCH_LOCATION_DISABLED,
        payload: { isChangeCountryDisabled: param },
      });
    };
  },
  resetAll: () => {
    return (dispatch) => {
      dispatch({ type: MAINMAP_PLACE_CONSTANT.RESET_ALL });
    };
  },
  resetStatus: () => {
    return (dispatch) => {
      dispatch({ type: MAINMAP_PLACE_CONSTANT.RESET_STATUS });
    };
  },
};

import React, { useState, useEffect } from 'react';
import { GeoJSON } from 'react-leaflet';

import { PopupGrid } from '@bvt-features/datex/component/PopupGrid';
import { PopupCircle } from '@bvt-features/datex/component/PopupCircle';
import { PopupLine } from '@bvt-features/datex/component/PopupLine';
import * as turf from '@turf/turf';
import * as L from 'leaflet';
import { renderToString } from 'react-dom/server';


// TODO: Index panning not working
/**
 * @author Joko Priyono , Randa <m.randa@bvarta.com>
 * @param {object} props
 * @param {Array<import('geojson').Feature>} props.grid
 * @param {Array<import('geojson').Feature>} props.flowMap
 * @param {Array<import('geojson').Feature>} props.flowPoint
 * @param {number} props.opacity
 * @param {index} props.layerIndex
 * @param {import('leaflet').Map} props.MapInstance
 */
export const GeoJsonDatexMap = (props) => {
  const [gridFeatures, setGridFeatures] = useState([]);
  const [circleFeatures, setCircleFeatures] = useState([]);
  const [lineFeatures, setLineFeatures] = useState([]);
  const [clickedCircleIdx, setClickedCircleIdx] = useState();
  const LAYER_INDEX = 201 + (props?.layerIndex || 0);

  /**
   * @type {import('leaflet-arrowheads').ArrowheadOptions}
   */
  let arrowHeadOptStyle = {
    fillColor: '#FF6700',
    fillOpacity: props?.opacity || 0.1,
    opacity: props?.opacity || 0.1,
    color: '#FF6700',
    fill: true,
  };

  /**
   * @description arrowhead instance
   */
  let arrowGJS = new L.GeoJSON([], {
    arrowheads: arrowHeadOptStyle,
    style: arrowHeadOptStyle,
    onEachFeature: function (ff, ll) {
      ll.bindPopup(
        L.popup({
          content: renderToString(<PopupLine feature={ff} />),
        })
      );
    },
  });

  useEffect(() => {
    setGridFeatures(props.grid || []);
    setCircleFeatures(props.flowPoint || []);
    setLineFeatures(props.flowMap || []);
  }, [props]);

  useEffect(() => {
    arrowGJS.setStyle(arrowHeadOptStyle);
    arrowGJS.setZIndex(LAYER_INDEX + 2);
    if (clickedCircleIdx) {
      const point = circleFeatures[clickedCircleIdx];
      const promises = [];

      lineFeatures.forEach((tmpFeat) => {
        let feat = JSON.parse(JSON.stringify(tmpFeat));
        promises.push(
          new Promise((resolve) => {
            const target = point.properties.index;
            const origin = feat.properties.origin;
            const destination = feat.properties.destination;
            const opt = { units: 'kilometers' };
            let findLine = checkLine(target, origin, destination);
            let find = checkOriginOrDest(findLine, circleFeatures, origin, destination);
            let radius = point.properties.ratio * 0.65;
            const circle1 = turf.circle(
              point.geometry.coordinates,
              radius,
              opt
            );
            radius = find.properties.ratio * 0.65;
            const circle2 = turf.circle(
              find.geometry.coordinates,
              radius,
              opt
            );
            const intersect1 = turf.lineIntersect(circle1, feat);
            const intersect2 = turf.lineIntersect(circle2, feat);
            if (
              intersect1.features.length > 0 &&
                intersect2.features.length > 0
            ) {
              const coor1 = intersect1.features[0].geometry.coordinates;
              const coor2 = intersect2.features[0].geometry.coordinates;
              feat.geometry.coordinates = [coor1, coor2];
              resolve(feat);
            } else {
              resolve();
            }
          })
        );
      });

      Promise.all(promises)
        .then((result) => result.filter((e) => e !== undefined))
        .then((result) => {
          arrowGJS.addData(result);
          arrowGJS.addTo(props.MapInstance);
          arrowGJS.setStyle({ ...arrowHeadOptStyle, ...{ opacity: 1 } });
        });
    }
    return () => {
      arrowGJS.removeFrom(props.MapInstance);
    };
  }, [clickedCircleIdx]);

  useEffect(() => {
    const mapInstanceClickHandler = () => {
      setClickedCircleIdx(undefined);
    };
    props.MapInstance.on('click', mapInstanceClickHandler);
    return () => {
      props.MapInstance.removeEventListener('click', mapInstanceClickHandler);
      arrowGJS.removeFrom(props.MapInstance);
    };
  }, []);

  return (
    <React.Fragment>
      {gridFeatures?.map((feature) => {
        const opt = {
          fillColor: feature?.properties?.density?.color_hex,
          fillOpacity: props?.opacity || 0.1,
          weight: 1,
          opacity: props?.opacity || 0.1,
          color: '#A0A6A9',
        };
        return (
          feature?.geometry?.coordinates[0] && (
            <GeoJSON
              data={feature}
              eventHandlers={{
                mouseover: function () {
                  this.setStyle({
                    fillOpacity: 1,
                  });
                },
                mouseout: function () {
                  this.setStyle({
                    fillOpacity: props?.opacity || 0.1,
                  });
                },
                click: function () {
                  this.setStyle({
                    fillOpacity: 1,
                  });
                },
              }}
              key={Number(feature.id)}
              pathOptions={opt}
            >
              <PopupGrid feature={feature} />
            </GeoJSON>
          )
        );
      })}
      {circleFeatures?.map((feature, index) => {
        const idx = index + 1;
        const selected = clickedCircleIdx === index;
        const opt = {
          fillColor: '#FF6700',
          fillOpacity: selected ? 1 : props?.opacity || 0.1,
          weight: 4,
          opacity: selected ? 1 : 0,
          color: '#26D0FF',
        };
        return (
          feature.geometry.coordinates[0] && (
            <GeoJSON
              data={turf.circle(
                feature.geometry?.coordinates,
                feature.properties.ratio * 550,
                { units: 'meters' }
              )}
              eventHandlers={{
                mouseover: function () {
                  this.setStyle({
                    fillOpacity: 1,
                    opacity: 1,
                  });
                },
                mouseout: function () {
                  if (!selected) {
                    this.setStyle({
                      fillOpacity: props?.opacity || 0.1,
                      opacity: 0,
                    });
                  }
                },
                click: () => setClickedCircleIdx(index),
              }}
              key={idx}
              pathOptions={opt}
            >
              <PopupCircle feature={feature} />
            </GeoJSON>
          )
        );
      })}
    </React.Fragment>
  );
};

const checkLine = (target, origin, destination) => {
  let findLine;
  if (origin === target) {
    findLine = 'destination';
  } else if (destination === target) {
    findLine = 'origin';
  }
  return findLine;
};

const checkOriginOrDest = (findLine, circleFeatures, origin, destination) => {
  let find;
  if (findLine === 'origin') {
    find = circleFeatures.find((f) => f.properties.index === origin);
  } else {
    find = circleFeatures.find((f) => f.properties.index === destination);
  }
  return find;
};

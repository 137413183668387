import { Select, Popover } from 'antd';
import React, { useState } from 'react';
import './_selectOption.scss';

/**
 * @param {import('antd').SelectProps} props
 */

const { Option } = Select;

export const SelectOption = (props) => {
  const [open, setOpen] = useState(false);
  const {
    dropdownRender,
    loading,
    onChange,
    options,
    placeholder,
    suffixIcon,
    value,
  } = props;

  const PopoverContent = () => {
    return (
      <div className='feature-mainmap-place container-popover'>
        <div className='arrow arrow---down' />
        <p className='feature-mainmap-place container-popover-text'>
          Contact our sales to subscribe
        </p>
      </div>
    );
  };

  function compareStatus(a, b) {
    return a.disabled - b.disabled;
  }

  return (
    <Select
      className='shared-selectOption'
      dropdownRender={dropdownRender}
      loading={loading}
      onChange={onChange}
      onDropdownVisibleChange={(e) => {
        setOpen(e);
      }}
      open={open}
      placeholder={placeholder}
      suffixIcon={suffixIcon}
      value={value}
    >
      {options?.sort(compareStatus).map((v, idx) => (
        <Option disabled={v.disabled} key={idx} value={v.value}>
          {v.disabled ? (
            <Popover
              content={<PopoverContent />}
              overlayClassName='shared-selectOption'
              placement='topLeft'
            >
              {v.label}
            </Popover>
          ) : (
            v.label
          )}
        </Option>
      ))}
    </Select>
  );
};

import { Button, Svg } from '@bvt-atoms';
import React, { useEffect, useState } from 'react';
import './CardDatexTelcoDataDetail.scss';
import { MainTable } from '@bvt-organisms';
import { CardDevice } from '../CardDevice';
import illustration from '@bvt-images/Illustration.png';
import { CardAreaDateTime } from '../CardAreaDateTime/CardAreaDateTime';
import { CardMoreInformation } from '../CardMoreInformation';
import Modal from 'antd/lib/modal/Modal';
import { IconClose } from '@bvt-icons';
import TesIcon from '@bvt-features/datex/component/CardDatexTelcoDataDetail/testIcon';
import { columnsDate, columnsTime } from './columnTelcoData';
import { PageList } from '@bvt-shared/component/PageList';

/**
 * @copyright Bhumi Varta Technology
 * @author Malik<malik.ibrahim@bvarta.com>
 * @param {Object} props
 * @param {String} props.area
 * @param {String} props.date
 * @param {String} props.time
 * @param {Object} props.dataSource
 * @param {String} props.catchmentBy
 * @param {String} props.catchmentTimeType

 */
const CarDatexTelcoDataDetail = (props) => {
  const pageSize = 5;
  const {
    date,
    area,
    time,
    dataSource,
    catchmentBy,
    catchmentTimeType,
    totalDevices,
  } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [filteredData, setFilteredData] = useState(dataSource);
  const [data, setData] = useState(dataSource.slice(minIndex, maxIndex));
  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(pageSize);
  const [currentPage, setCurrentPage] = useState(1);

  const handleChangePage = (page) => {
    const min = (page - 1) * pageSize;
    const max = page * pageSize;
    setCurrentPage(page);
    setMinIndex(min);
    setMaxIndex(max);
    setData(filteredData.slice(min, max));
  };

  useEffect(() => {
    setData(filteredData.slice(minIndex, maxIndex));
  }, [minIndex, maxIndex]);

  return (
    <div className='CarDatexTelcoDataDetail__container'>
      <Button
        className='CarDatexTelcoDataDetail__btn-summary'
        onClick={() => setIsModalOpen(true)}
      >
        <Svg
          className='CarDatexTelcoDataDetail__btn-summary-img'
          src='ic-info'
        />
        <span className='CarDatexTelcoDataDetail__btn-summary-text'>
          Layer Summary
        </span>
      </Button>

      <CardMoreInformation
        data={[
          { key: 'Catchment by', value: catchmentBy },
          { key: 'Time Type', value: catchmentTimeType },
        ]}
      />
      <CardDevice
        image={illustration}
        measurement='Devices'
        title={`${catchmentBy} Device(s)`}
        total={totalDevices}
      />
      <CardAreaDateTime
        area={area || '-'}
        date={date || '-'}
        time={time || '-'}
      />

      <div className='CarDatexTelcoDataDetail__card'>
        <div className='CarDatexTelcoDataDetail__card-header'>
          <span className='CarDatexTelcoDataDetail__card-header-title'>
            Density Counts
          </span>
        </div>
        <div className='CarDatexTelcoDataDetail__card-body'>
          <div className='CarDatexTelcoDataDetail__card-count'>
            <MainTable
              bordered={false}
              className='CarDatexTelcoDataDetail__table'
              columns={
                ['Specific Time', 'Popular Time'].includes(catchmentTimeType)
                  ? columnsTime
                  : columnsDate
              }
              dataSource={data}
            />
            <PageList
              className='CarDatexTelcoDataDetail__page-list__page-item'
              currentPage={currentPage}
              onChange={(e) => handleChangePage(e)}
              pageSize={pageSize}
              size='small'
              totalData={filteredData.length}
            />
          </div>
        </div>
      </div>

      <Modal
        centered
        className='CarDatexTelcoDataDetail__modal'
        closeIcon={
          <IconClose
            background='white'
            className='feature-map LayoutDatexSidebarWrapper__header__title__button'
            onClick={() => setIsModalOpen(false)}
          />
        }
        footer={null}
        open={isModalOpen}
        style={{ zIndex: '999999 !important' }}
        title={'Layer Summary'}
      >
        <div className='CarDatexTelcoDataDetail__modal-layer-title'>
          <TesIcon />
          <span className='CarDatexTelcoDataDetail__modal-layer-title-text'>
            {catchmentBy}
          </span>
        </div>
        <CardMoreInformation
          className={'CarDatexTelcoDataDetail__modal-more'}
          data={[
            { key: 'Catchment by', value: catchmentBy },
            { key: 'Time Type', value: catchmentTimeType },
          ]}
        />
        <CardDevice
          className={'CarDatexTelcoDataDetail__modal-device'}
          image={illustration}
          measurement='Devices'
          title={`${catchmentBy} Device(s)`}
          total={totalDevices}
        />
        <CardAreaDateTime
          area={area || '-'}
          className={'CarDatexTelcoDataDetail__modal-adt'}
          date={date || '-'}
          time={time || '-'}
        />
      </Modal>
    </div>
  );
};

export { CarDatexTelcoDataDetail };

import React from 'react';
import { Pagination } from 'antd';
import './_pageList.scss';
/**
 * @version 1.0
 * @copyright Bhumi Varta Technology
 * @author Malik <malik.ibrahim@bvarta.com>
 * @param {Object} props
 * @param {String} props.className
 * @param {Number} props.currentPage
 * @param {Boolean} props.disabled
 * @param {(current, type, originalElement)=>originalElement} props.itemRender
 * @param {Object} props.locale
 * @param {()=>void} props.onChange
 * @param {Number} props.pageSize
 * @param {Boolean} props.showLessItems
 * @param {Boolean | Object} props.showQuickJumper
 * @param {Boolean} props.showSizeChanger
 * @param {Boolean} props.simple
 * @param {'small' | 'default'} props.size
 * @param {Number} props.totalData
 */

export const PageList = (props) => {
  const {
    className,
    currentPage,
    disabled,
    itemRender,
    locale,
    onChange,
    pageSize,
    showLessItems,
    showSizeChanger,
    showQuickJumper,
    simple,
    size,
    totalData,
  } = props;

  return (
    <Pagination
      className={className}
      current={currentPage}
      disabled={disabled}
      itemRender={itemRender}
      locale={locale}
      onChange={onChange}
      pageSize={pageSize}
      showLessItems={showLessItems}
      showQuickJumper={showQuickJumper}
      showSizeChanger={showSizeChanger}
      simple={simple}
      size={size}
      total={totalData}
    />
  );
};

PageList.defaultProps = {
  className: 'page-list',
  currentPage: 1,
  disabled: false,
  itemRender: (current, type, originalElement) => originalElement,
  locale: {},
  onChange: () => {},
  pageSize: 3,
  showLessItems: true,
  showQuickJumper: false,
  showSizeChanger: false,
  simple: false,
  size: 'default',
  totalData: '',
};

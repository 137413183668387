/* eslint-disable max-lines */
/* eslint-disable indent */
/* eslint-disable no-unsafe-optional-chaining */
import React, { Fragment, useEffect, useState } from 'react';
import { useMap } from 'react-leaflet';

import { LayoutDatexSidebarWrapper } from '@bvt-features/datex/component/LayoutDatexSidebarWrapper';
import { useMainMap } from '@bvt-features/mainmap/mainmap/hook/useMainMap';
import { DatexTelcoStepOne } from '../../component/DatexTelcoStepOne';
import { useDatex } from '@bvt-features/datex/hooks';
import { CardListItemDatex } from '@bvt-features/datex/component/CardListItemDatex';
import { Popup } from '@bvt-atoms';
import { ID_SOURCE_CONSTANT } from '@bvt-shared/constant/ID_SOURCE_CONSTANT';
import { notification } from 'antd';
import { GeoJsonDatexContainer } from '../GeoJsonDatexContainer';
import { useLegend } from '@bvt-features/mainmap/legend/hooks';
import _, { find, groupBy, isArray, isEmpty, map, uniqBy } from 'lodash';
import { DatexStepPOICategoryCollection } from '@bvt-features/datex/component/DatexStepPOICategoryCollection';
import { DatexStepPOIAreaSelection } from '@bvt-features/datex/component/DatexStepPOIAreaSelection';
import { DatexStepTelcoDataVisualization } from '@bvt-features/datex/component/DatexStepTelcoDataVisualization';
import { PREVIEW_DATEX_GRID_DATA_CONSTANT } from '@bvt-features/datex/constant/PREVIEW_DATEX_GRID_DATA_CONSTANT';
import { PREVIEW_DATEX_MOVEMENT_DATA_CONSTANT } from '@bvt-features/datex/constant/PREVIEW_DATEX_MOVEMENT_DATA_CONSTANT';
import { usePlace } from '@bvt-features/mainmap/place/hooks/usePlace';
import * as L from 'leaflet';
import { DatextStepTwoThematic } from '@bvt-features/datex/component/DatextStepTwoThematic/DatextStepTwoThematic';
import { DatextStepThreeThematic } from '@bvt-features/datex/component/DatextStepThreeThematic/DatextStepThreeThematic';
import { DatextStepFourThematic } from '@bvt-features/datex/component/DatextStepFourThematic';
import {
  STEP3SINGLE,
  nationalLevelRegion,
  levelDataShouldVillage,
  excludeLandValue,
  percentageValueLegend,
  generalCurrencyValueLegend,
  billionCurrencyValueLegend,
} from '@bvt-features/datex/constant/THEMATIC_RULE';
import { getIcon } from '@bvt-shared/utils/iconUtils';
import { DatexStepPOIAreaSelectionRinjani } from '@bvt-features/datex/component/DatexStepPOIAreaSelectionRinjani';
import { DatextStepFourThematicRinjani } from '@bvt-features/datex/component/DatextStepFourThematicRinjani';
/**
 * @copyright Bhumi Varta Technology
 * @author Malik<malik.ibrahim@bvarta.com>
 */

export const DatexContainer = () => {
  const initialMap = useMap();
  const mainmap = useMainMap();
  const datex = useDatex();
  const legend = useLegend();
  const PLACE = usePlace();

  // TODO: add internal state for testing step
  const [currentStep, setCurrentStep] = useState(0);
  // taro di state usedatex
  const [id, setId] = useState(1);
  const [isEdit, setIsEdit] = useState(false);
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [isNextShow, setIsNextShow] = useState(false);
  const [isBackDisabled, setBackDisabled] = useState(false);
  const [isBackShow, setIsBackShow] = useState(false);
  const [isShowButton, setIsShowButton] = useState(false);
  const [openEditPOI, setOpenEditPOI] = useState(false);

  const initialState = {
    id: null,
    sort: null,
    type: '',
    province: null,
    city: null,
    dateList: [],
    catchmentType: null,
    timeType: '',
    year: null,
    month: null,
    timeList: [],
    densityType: [],
    status_ANALYZE: 'IDLE',
    options: {
      value: null,
      data: [],
    },
    result: {
      grid: null,
      flow: null,
      layerSummary: [],
    },
    isShowVisibility: true,
    poiIndustry: null,
    poiGroup: null,
    poiCategory: null,
    poiBrands: [],
    poiBrandLabels: [],
    poiCriterias: [],
    poiCriteriaLabels: [],
    poiProvince: undefined,
    poiCity: undefined,
    poiDistrict: undefined,
    poiVillage: undefined,
    poiArea: null,
    thematicCategory: null,
    thematicSubCategory: null,
    thematicItems: [],
    thematicItemsLabel: [],
    thematicProvince: undefined,
    thematicCity: undefined,
    thematicDistrict: undefined,
    thematicVillage: undefined,
    thematicArea: 'National',
    thematicRegionCode: null,
    thematicSelectedYearIndex: 0,
    defaultValueBrands: [],
    defaultValueCriterias: [],
    advanceMap: {},
    cityId: null,
  };

  const [state, setState] = useState(initialState);
  const [showPoiSearchResult, setShowSearchResult] = useState(false);

  const [modalState, setModalState] = useState({
    isShow: false,
    selectedID: null,
    selectedCatchmentBy: '',
  });

  const internalHandleOnCloseDatex = () => {
    mainmap.setActiveMenu('');
    legend.toggleOpenLegend(false);
    ['provinsi', 'kota', 'kecamatan', 'desa'].forEach((placeType) =>
      PLACE.setGeojson({ [placeType]: null })
    );
    // datex.resetState();
    resetState();
  };

  const resetState = () => {
    setState({
      ...initialState,
      options: { value: null, data: datex?.state?.legendColors },
    });
    setIsNextDisabled(true);
    setIsEdit(false);
    setCurrentStep(0);
  };

  const internalHandleOnValidOrInvalid = (e) => {
    setIsNextDisabled(!e);
  };

  const internalHandleOnChangeDensityTypeStep2 = (e) => {
    setState({ ...state, densityType: e });
  };

  const isNationalLevelRegion = (subcategoryLabel, itemLabel) => {
    return (
      nationalLevelRegion.includes(subcategoryLabel.toLowerCase()) ||
      itemLabel?.toLowerCase()?.includes('grdp by province')
    );
  };

  const internalHandleNext = () => {
    if (state?.type === 'THEMATIC' && currentStep === 1) {
      datex?.getThematicSubcategoryList({ id: state?.thematicCategory?.key });
    }
    if (state?.type === 'THEMATIC_RINJANI' && currentStep === 1) {
      datex?.getThematicSubcategoryListRinjani({ id: state?.thematicCategory?.key,countryId:PLACE.state.country_mandala.country_id });
    }
    if (state?.type === 'THEMATIC' && currentStep === 2) {
      datex?.getThematicItemList({ id: state?.thematicSubCategory?.key });
    }
    if (state?.type === 'THEMATIC_RINJANI' && currentStep === 2) {
      datex?.getThematicItemList({ id: state?.thematicSubCategory?.key ,countryId:PLACE.state.country_mandala.country_id});
    }

    const maxStep =
      listStep?.filter((v) => v?.menu?.includes(state?.type))?.length - 1;
    if (state?.type === 'POI' || state?.type === 'POI_RINJANI') {
      switch (true) {
        case currentStep === 1:
          datex.getGroupCategoryListByIndustry({ id: state?.poiIndustry?.key,countryId:PLACE.state.country_mandala.country_id });
          break;
        default:
          setCurrentStep(currentStep + 1);
          break;
      }
    }
    if (openEditPOI && currentStep === 2) {
      setCurrentStep(2);
      setOpenEditPOI(false);
    } else if (
      currentStep < maxStep &&
      (!(
        currentStep === 3 &&
        state.type === 'THEMATIC' &&
        isNationalLevelRegion(
          state.thematicSubCategory?.label,
          state.thematicItemsLabel?.[0]
        )
      ) ||
        (currentStep < 3 && isEdit && state.type === 'THEMATIC'))
    ) {
      // handling thematic national level ^ yang else if diatas
      if (showPoiSearchResult) {
        setCurrentStep(3);
      } else {
        if (
          isEdit &&
          currentStep === 3 &&
          state.type === 'THEMATIC' &&
          isNationalLevelRegion(
            state.thematicSubCategory?.label,
            state.thematicItemsLabel?.[0]
          )
        ) {
          // untuk handling saat edit datex thematic, agar data yang harus nasional tidak mengirim data administratif sebelumnya
          setState({
            ...state,
            thematicArea: 'National',
            thematicProvince: undefined,
            thematicCity: undefined,
            thematicDistrict: undefined,
          });
        }
        setCurrentStep(currentStep + 1);
      }
    } else {
      setCurrentStep(0);

      const payload = {
        id: state?.id,
        kode_admin: state?.city,
        id_source: ID_SOURCE_CONSTANT,
        type: state?.catchmentType,
        start_date: state?.dateList?.[0] || '',
        end_date: state?.dateList?.length > 1 ? state?.dateList?.at(-1) : '',
        time: state?.timeList || [],
        density_type: null,
        month: state?.month,
        year: state?.year,
      };

      if (
        state?.densityType?.includes('grid') &&
        state?.densityType?.includes('flow')
      ) {
        payload.density_type = 'both';
      } else if (state?.densityType?.includes('grid')) {
        payload.density_type = 'grid';
      } else if (state?.densityType?.includes('flow')) {
        payload.density_type = 'flow';
      }

      if (isEdit) {
        const newData = datex.state?.dataLayerList.map((el) =>
          el.id === state?.id
            ? { ...state, advanceMap: PLACE.state.advance.list }
            : el
        );
        datex.replaceFeatureToDataLayer({ data: newData });
        legend.isLoadingData(true);
        legend.removeDataByPrefix('DATEX');
      } else {
        // Will be updated soon
        if (state?.type === 'TELCO') {
          datex.addFeatureToDataLayer({
            data: {
              ...state,
              advanceMap: PLACE.state.advance.list,
            },
          });
        } else if (state?.type === 'THEMATIC' || state?.type === 'THEMATIC_RINJANI') {
          datex.addFeatureToDataLayer({
            data: {
              ...state,
              advanceMap:
                state.thematicProvince ||
                state.thematicCity ||
                state.thematicDistrict
                  ? PLACE.state.advance.list
                  : {
                      provinsi: null,
                      kota: null,
                      kecamatan: null,
                      desa: null,
                    },
            },
          });
        } else if (state?.type === 'POI' || state?.type === 'POI_RINJANI') {
          datex.addFeatureToDataLayer({
            data: { ...state, advanceMap: PLACE.state.advance.list },
          });
        }

        const layerKey = `DATEX_${id}`;
        let layerTitle = 'DATEX_';

        mainmap.registerLayer({
          isActive: true,
          key: layerKey,
          opacity: 0.5,
          title: layerTitle,
          description: [],
        });
        setId(id + 1);
      }

      // Will be updated soon
      if (state?.type === 'POI') {
        const payloadPOI = {
          id: state?.id,
          categoryId: state?.poiCategory?.key,
          brands: state?.poiBrands,
          criterias: state?.poiCriterias,
          provinceId: state?.poiProvince,
          cityId: state?.poiCity,
          districtId: state?.poiDistrict,
          villageId: state?.poiVillage,
        };

        datex.doAnalyzeDatexPoi(payloadPOI);
      } if (state?.type === 'POI_RINJANI') {
        const payloadPOI = {
          id: state?.id,
          categoryId: state?.poiCategory?.key,
          brands: state?.poiBrands,
          criterias: state?.poiCriterias,
          provinceId: state?.poiProvince,
          cityId: state?.poiCity,
          districtId: state?.poiDistrict,
          villageId: state?.poiVillage,
          countryId: PLACE.state.country_mandala.country_id,
        };
        datex.doAnalyzeDatexPoi(payloadPOI);
      }else if (state?.type === 'TELCO') {
        datex.doAnalyzeDatexTelcoData(payload);
      } else if (state?.type === 'THEMATIC' || state?.type === 'THEMATIC_RINJANI') {
        let levelRegion = '';
        let levelData = '';
        let region;
        if (
          state.thematicDistrict &&
          !isNationalLevelRegion(
            state.thematicSubCategory?.label,
            state.thematicItemsLabel?.[0]
          )
        ) {
          levelRegion = 'district';
          levelData = 'village';
          region = state.thematicRegionCode;
        } else if (
          state.thematicCity &&
          !isNationalLevelRegion(
            state.thematicSubCategory?.label,
            state.thematicItemsLabel?.[0]
          )
        ) {
          levelRegion = 'regency';
          levelData = 'district';
          region = state.thematicRegionCode;
        } else if (
          state.thematicProvince &&
          !isNationalLevelRegion(
            state.thematicSubCategory?.label,
            state.thematicItemsLabel?.[0]
          )
        ) {
          levelRegion = 'province';
          levelData = 'regency';
          region = state.thematicRegionCode;
        } else {
          levelRegion = 'national';
          levelData = 'province';
          region = null;
        }

        if (
          levelDataShouldVillage.includes(
            state.thematicSubCategory?.label?.toLowerCase()
          )
        ) {
          levelData = 'village';
        }

        const payloadThematic = {
          id: state?.id,
          color: 'random',
          attribute: state?.thematicItems,
          region: region,
          levelData: levelData,
          levelRegion: levelRegion,
          country_id: PLACE.state.country_mandala?.country_id ?? 1
        };

        datex.doAnalyzeDatexThematic(payloadThematic);
      }
      resetState();
    }
  };

  const internalHandleBack = () => {
    if (openEditPOI && currentStep === 2) {
      setCurrentStep(2);
      setOpenEditPOI(false);
      setState({
        ...state,
        poiBrands: state.defaultValueBrands,
        poiCriterias: state.defaultValueCriterias,
      });
    } else if (['THEMATIC_RINJANI','THEMATIC'].includes(state?.type) && currentStep === 1) {
      setState({
        ...state,
        thematicCategory: null,
      });
      setCurrentStep(currentStep - 1);
    } else if (['THEMATIC_RINJANI','THEMATIC'].includes(state?.type) && currentStep === 2) {
      setState({
        ...state,
        thematicSubCategory: null,
      });
      setCurrentStep(currentStep - 1);
    } else if (['THEMATIC_RINJANI','THEMATIC'].includes(state?.type) && currentStep === 3) {
      setState({
        ...state,
        thematicItems: [],
        thematicItemsLabel: [],
      });
      setCurrentStep(currentStep - 1);
    } else if (['THEMATIC_RINJANI','THEMATIC'].includes(state?.type) && currentStep === 4) {
      setCurrentStep(currentStep - 1);
      setState({
        ...state,
        thematicProvince: undefined,
        thematicCity: undefined,
        thematicDistrict: undefined,
        thematicVillage: undefined,
      });
      ['provinsi', 'kota', 'kecamatan', 'desa'].forEach((placeType) =>
        PLACE.setGeojson({ [placeType]: null })
      );
    } else if (state?.type === 'POI') {
      if (currentStep === 3) {
        setState({
          ...state,
          poiProvince: undefined,
          poiCity: undefined,
          poiDistrict: undefined,
          poiVillage: undefined,
        });
        ['provinsi', 'kota', 'kecamatan', 'desa'].forEach((placeType) =>
          PLACE.setGeojson({ [placeType]: null })
        );
        if (showPoiSearchResult) {
          setCurrentStep(1);
        } else {
          setCurrentStep(currentStep - 1);
        }
      } else {
        setCurrentStep(currentStep - 1);
      }
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const internalHandleOnAddLayer = () => {
    setCurrentStep(currentStep + 1);
  };

  const internalHandleOnMouseEnter = () => {
    initialMap.dragging.disable();
    initialMap.scrollWheelZoom.disable();
  };

  const internalHandleOnMouseLeave = () => {
    initialMap.dragging.enable();
    initialMap.scrollWheelZoom.enable();
  };

  const internalHandleOnChangeStep1 = (params) => {
    const { data, type } = params;
    let dateList;
    if (
      data?.dateList &&
      data?.dateList?.length > 7 &&
      data?.catchmentType === 'total' &&
      data?.showCalendar === true
    ) {
      dateList = data?.dateList?.slice(0, 7);
    } else {
      dateList = data?.dateList;
    }
    const meta = {
      meta: {
        filter: {
          id_province: data?.province ?? state.province,
          id_city: data?.cityId ?? state.cityId,
          id_source: ID_SOURCE_CONSTANT,
        },
      },
    };
    if (
      (data?.province || data?.city) &&
      (data?.province !== state.province || data?.city !== state?.city)
    ) {
      PLACE.setGeojson({ provinsi: null });
      PLACE.setGeojson({ kota: null });
      PLACE.get_advance({ params: meta });
    }
    setState({
      ...state,
      id: state?.id || id,
      province: data?.province,
      city: data?.city,
      dateList: dateList,
      timeList: data?.timeList,
      catchmentType: data?.catchmentType,
      type: type,
      timeType: data?.timeType,
      poiIndustry: data?.poiIndustry,
      month: data?.month,
      year: data?.year,
      thematicCategory: data?.thematicCategory,
      cityId: data?.cityId,
    });
  };

  /**
   *
   * @param {Object} params
   * @param {Object} params.poiGroup
   * @param {String|Number} params.poiGroup.key
   * @param {String} params.poiGroup.value
   * @param {String} params.poiGroup.icon
   * @param {Object} params.poiCategory
   * @param {String|Number} params.poiCategory.key
   * @param {String} params.poiCategory.value
   * @param {String} params.poiCategory.icon
   * @param {String} params.poiCategory.index
   * @param {Array<String|Number>} params.poiBrands
   * @param {Array<String>} params.poiCriterias
   */
  const internalHandleOnChangeStep2Poi = (params) => {
    setState({
      ...state,
      poiGroup: {
        key: params?.poiGroup?.key || state?.poiGroup?.key,
        value: params?.poiGroup?.value || state?.poiGroup?.value,
        icon: params?.poiGroup?.icon || state?.poiGroup?.icon,
      },
      poiCategory: {
        key: params?.poiCategory?.key || state?.poiCategory?.key,
        value: params?.poiCategory?.value || state?.poiCategory?.value,
        icon: params?.poiCategory?.icon || state?.poiCategory?.icon,
        index: params?.poiCategory?.index || state?.poiCategory?.index,
      },
      poiBrands: params?.poiBrands || state?.poiBrands,
      poiBrandLabels: params?.poiBrandLabels || state?.poiBrandLabels,
      poiCriterias: params?.poiCriterias || state?.poiCriterias,
      poiCriteriaLabels: params?.poiCriteriaLabels || state?.poiCriteriaLabels,
    });
  };

  const internalHandleOnChangeStep3Poi = (params) => {
    setState({
      ...state,
      poiProvince: params?.province,
      poiCity: params?.city,
      poiDistrict: params?.district,
      poiVillage: params?.village,
      poiArea: params?.poiArea,
    });
    const meta = {
      meta: {
        filter: {
          id_province: params?.province,
          id_city: params?.city,
          id_district: params?.district,
          id_village: params?.village,
          id_source: ID_SOURCE_CONSTANT,
        },
      },
    };
    if (params?.province) {
      PLACE.get_advance({ params: meta });
    }
  };

  const internalHandleOnChangeStep3PoiRinjani = (params) => {
    const countryId = PLACE.state.country_mandala.country_id
    if(countryId == 1){
      return internalHandleOnChangeStep3Poi(params)
    }
    setState({
      ...state,
      poiProvince: params?.province,
      poiCity: params?.city,
      poiDistrict: params?.district,
      poiVillage: params?.village,
      poiArea: params?.poiArea,
    });

    
    let keyRegion = {}
    if(countryId == 2){
      keyRegion["provinsi"] = "region_id"
      keyRegion["kota"] = "prefecture_id"
      keyRegion["kecamatan"] = "district_id"
    }else{
      keyRegion["provinsi"] = "province_id"
      keyRegion["kota"] = "district_id"
      keyRegion["kecamatan"] = "commune_id"
    }
    const payload = {
      meta: {
        filter: {
          country_id :countryId ,
          [keyRegion["provinsi"]]: params?.province,
          ...(params?.city && { [keyRegion["kota"]]: params?.city}),
          ...(params?.district && { [keyRegion["kecamatan"]]: params?.district })
        },
      },
    };

    if (params?.province) {
      PLACE.get_advance_inter({params:payload});
    }
  };

  /**
   * @param {Object} params
   * @param {String|Number} params.key
   * @param {String} params.value
   * @param {String} params.icon
   */
  const internalHandleOnChangeStep2Thematic = (params) => {
    setState({
      ...state,
      thematicSubCategory: params,
    });
  };

  /**
   *
   * @param {Array<string>} params
   */
  const internalHandleOnChangeStep3Thematic = (params) => {
    setState({
      ...state,
      thematicItems: params.key,
      thematicItemsLabel: params.label,
    });
  };

  const internalHandleOnChangeStep4Thematic = (params) => {
    setState({
      ...state,
      thematicProvince: params?.province,
      thematicCity: params?.city,
      thematicDistrict: params?.district,
      thematicVillage: params?.village,
      thematicArea: params?.thematicArea ?? 'National',
      thematicRegionCode: params?.thematicRegionCode,
    });
    const meta = {
      meta: {
        filter: {
          id_province: params?.province,
          id_city: params?.city,
          id_district: params?.district,
          id_village: params?.village,
          id_source: ID_SOURCE_CONSTANT,
        },
      },
    };
    ['provinsi', 'kota', 'kecamatan', 'desa'].forEach((placeType) => {
      if (
        (placeType === 'desa' && !params?.village) ||
        (placeType === 'kecamatan' && !params?.city) ||
        (placeType === 'kota' && !params?.district) ||
        (placeType === 'provinsi' && !params?.province)
      ) {
        PLACE.setGeojson({ [placeType]: null });
      }
    });
    if (params?.province) {
      PLACE.get_advance({ params: meta });
    }
  };

  
  const internalHandleOnChangeStep4ThematicRinjani = (params) => {
    const countryId = PLACE.state.country_mandala.country_id
    if(countryId == 1){
      return internalHandleOnChangeStep4Thematic(params)
    }
    setState({
      ...state,
      thematicProvince: params?.province,
      thematicCity: params?.city,
      thematicDistrict: params?.district,
      thematicVillage: params?.village,
      thematicArea: params?.thematicArea ?? 'National',
      thematicRegionCode: params?.thematicRegionCode,
    });

    ['provinsi', 'kota', 'kecamatan', 'desa'].forEach((placeType) => {
      if (
        (placeType === 'desa' && !params?.village) ||
        (placeType === 'kecamatan' && !params?.city) ||
        (placeType === 'kota' && !params?.district) ||
        (placeType === 'provinsi' && !params?.province)
      ) {
        PLACE.setGeojson({ [placeType]: null });
      }
    });
    
    
    let keyRegion = {}
    if(countryId == 2){
      keyRegion["provinsi"] = "region_id"
      keyRegion["kota"] = "prefecture_id"
      keyRegion["kecamatan"] = "district_id"
    }else{
      keyRegion["provinsi"] = "province_id"
      keyRegion["kota"] = "district_id"
      keyRegion["kecamatan"] = "commune_id"
    }
    const payload = {
      meta: {
        filter: {
          country_id :countryId ,
          [keyRegion["provinsi"]]: params?.province,
          ...(params?.city && { [keyRegion["kota"]]: params?.city}),
          ...(params?.district && { [keyRegion["kecamatan"]]: params?.district })
        },
      },
    };

    if (params?.province) {
      PLACE.get_advance_inter({params:payload});
    }
  };

  const internalHandleOpenEditPOI = (edit) => {
    setOpenEditPOI(edit);
  };

  const internalHandleOnDeleteLayer = (params) => {
    setModalState({
      isShow: true,
      selectedID: params.ID,
      selectedCatchmentBy: params.catchmentBy,
    });w
  };

  const internalGetLegendId = (
    data = {},
    thematicSelectedYearIndex = 0,
    thematicSubCategory = ''
  ) => {
    let tempLegendlist = [];
    if (data?.grid && Object.keys(data?.grid)?.length > 0) {
      const groupBySubclass = Object.keys(
        groupBy(
          data?.grid?.grid_density?.features,
          (w) => w?.properties?.density?.legend_class
        )
      );
      tempLegendlist.push(
        ...groupBySubclass?.map((w) => {
          const findLegendColor = datex.state?.legendColors?.find(
            (a) => a?.class_name === w
          );
          return {
            key: `DATEX_${findLegendColor?.class_name?.split(' ')?.join('_')}`,
            title: `${findLegendColor?.name} - Telco Data`,
            color: findLegendColor?.hex,
            type: 'SQUARE',
          };
        })
      );
    }
    if (data?.flow && Object.keys(data?.flow)?.length > 0) {
      tempLegendlist.push({
        key: 'DATEX_flow',
        title: 'Flow Point - Telco Data',
        color: '#FF6700',
        type: 'CIRCLE',
      });
    }

    // Thematic
    if (isArray(data)) {
      data?.[thematicSelectedYearIndex]?.legend?.forEach((v) => {
        const isPercentageValue = percentageValueLegend.includes(
          thematicSubCategory?.toLowerCase()
        );
        const isGeneralCurrencyValueLegend =
          generalCurrencyValueLegend.includes(
            thematicSubCategory?.toLowerCase()
          );
        const isBillionCurrencyValueLegend =
          billionCurrencyValueLegend.includes(
            thematicSubCategory?.toLowerCase()
          );

        let title = v?.value;
        if (isPercentageValue) {
          title = `${v?.value} (%)`;
        } else if (isGeneralCurrencyValueLegend) {
          title = `${v?.value} (IDR)`;
        } else if (isBillionCurrencyValueLegend) {
          title = `${v?.value} (IDR Billion)`;
        }
        tempLegendlist.push({
          key: `DATEX_Thematic_${v?.value}`,
          title: `${title} - Thematic`,
          color: v?.color,
          type: 'SQUARE',
        });
      });
    }

    if (data?.poi?.features?.length) {
      data?.poi?.features?.forEach((v) => {
        tempLegendlist.push({
          key: `DATEX_POI_${v?.properties?.category_name}`,
          icon: (
            <img
              alt='icon datex'
              src={getIcon('poi', v?.properties?.category_icon)}
              style={{ width: '1rem' }}
            />
          ),
          title: `${v?.properties?.category_name} - POI`,
          type: 'ICON',
        });
      });
    }
    return tempLegendlist;
  };

  const internalMappingDatexLayerList = (yearIndex = undefined) => {
    let tempLegendlist = [];
    datex.state?.dataLayerList?.map((v) => {
      const mappedDatexData = internalGetLegendId(
        v?.result,
        yearIndex || v?.thematicSelectedYearIndex, // yearIndex for internalHandleOnSliderChange
        v?.thematicSubCategory?.label
      );
      if (['THEMATIC_RINJANI','THEMATIC'].includes(v.type)) {
        const deleteThematicLegend = _.flatMap(v.result, 'legend').map(
          (item) => `DATEX_Thematic_${item.value}`
        );
        legend.removeDataByKey(deleteThematicLegend);
        tempLegendlist = [...tempLegendlist, ...mappedDatexData];
      } else {
        legend.removeDataByKey(map(mappedDatexData, (v) => v?.key));
        tempLegendlist = [...tempLegendlist, ...mappedDatexData];
      }
    });
    return tempLegendlist;
  };

  const internalHandleConfirmDeleteLayer = (id) => {
    const deletedLegendId = map(
      internalGetLegendId(
        find(datex.state?.dataLayerList, (v) => v?.id === id)?.result,
        find(datex.state?.dataLayerList, (v) => v?.id === id)
          ?.thematicSelectedYearIndex,
        find(datex.state?.dataLayerList, (v) => v?.id === id)
          ?.thematicSubCategory?.label
      ),
      (v) => v?.key
    );

    // Get Thematic Legend Deleted id
    const thematicResult = _.flatMap(
      datex.state?.dataLayerList?.filter((v) => ['THEMATIC_RINJANI','THEMATIC'].includes(v.type)),
      'result'
    );
    const thematicDeletedLegendId = _.flatMap(thematicResult, 'legend').map(
      (item) => `DATEX_Thematic_${item?.value}`
    );

    datex.removeFeatureFromDatalayer({ id });
    legend.removeDataByKey([...deletedLegendId, ...thematicDeletedLegendId]);
    setModalState({ ...modalState, isShow: false });

    const layerKey = `DATEX_${id}`;
    mainmap.removeLayer(layerKey);
  };

  const internalHandleOnChangeLayerPosition = (sortedData) => {
    datex.replaceFeatureToDataLayer({ data: sortedData });
  };

  const internalHandleOnEdit = (id) => {
    const data = datex.state.dataLayerList.find((el) => el.id === id);
    setState(data);
    setIsEdit(true);
    if (['THEMATIC_RINJANI','THEMATIC'].includes(data.type)) {
      ['provinsi', 'kota', 'kecamatan', 'desa'].forEach((placeType) =>
        PLACE.setGeojson({ [placeType]: null })
      );
    }
    setCurrentStep(1);
  };

  const internalHandleOnSelectOptionChange = (id, val) => {
    const newData = datex.state.dataLayerList.map((el) =>
      el.id === id ? { ...el, options: { ...el.options, value: val } } : el
    );
    datex.replaceFeatureToDataLayer({ data: newData });
  };

  const internalHandleOnSliderChange = (id, val) => {
    const newData = datex.state.dataLayerList.map((el) =>
      el.id === id ? { ...el, thematicSelectedYearIndex: val } : el
    );
    datex.replaceFeatureToDataLayer({ data: newData });

    // change legend
    const tempLegendlist = internalMappingDatexLayerList(val);
    legend.pushData({
      data: uniqBy([...tempLegendlist], 'key'),
    });
    legend.toggleOpenLegend(true);
  };

  const internalHandleOnVisible = (idLayer, val) => {
    const layerKey = `DATEX_${idLayer}`;
    const originalOpacity = _.find(
      mainmap.state.layerVisibilityList,
      (k) => k.key === layerKey
    ).opacity;
    mainmap.updateLayer({
      isActive: val,
      key: layerKey,
      opacity: originalOpacity || 0,
    });
    const newData = datex.state.dataLayerList.map((el) =>
      el.id === idLayer ? { ...el, isShowVisibility: val } : el
    );
    datex.replaceFeatureToDataLayer({ data: newData });
  };

  const internalCancelTextGet = () => {
    if (openEditPOI && currentStep === 2) {
      return 'Cancel Edit';
    } else if (isEdit && currentStep === 1) {
      return 'Cancel Edit';
    } else {
      return 'Back';
    }
  };

  const internalNextTextGet = () => {
    if (openEditPOI && currentStep === 2) {
      return 'Save';
    } else {
      return 'Next';
    }
  };

  const internalHandleSearchProvince = (e) => {
    const params = {
      meta: {
        filter: { keyword: e },
        page: 1,
        pageSize: 100,
      },
    };
    PLACE.get_province({ params });
  };

  const internalHandleSearchProvinceRinjani = (e) => {
    const params = {
      meta: {
        filter: { keyword: e ,country_id: PLACE.state.country_mandala.country_id},
        page: 1,
        pageSize: 100,
      },
    };
    PLACE.state.country_mandala.country_id == 1 ? PLACE.get_province({ params }) : PLACE.get_province_inter({ params })
  };

  const internalHandleSearchCity = (e, province) => {
    const params = {
      meta: {
        filter: {
          keyword: e,
          id_province: province,
        },
        page: 1,
        pageSize: 100,
      },
    };
    PLACE.get_city({ params });
  };

  const internalHandleSearchCityRinjani = (e, province) => {
    if(PLACE.state.country_mandala.country_id == 1){
      const params = {
        meta: {
          filter: { keyword: e,id_province: province},
          page: 1,
          pageSize: 100,
        },
      };
      PLACE.get_city({ params })
    }else{
      const params = {
        meta: {
          filter: { keyword: e ,country_id: PLACE.state.country_mandala.country_id,parent_id: province},
          page: 1,
          pageSize: 100,
        },
      };
      PLACE.get_city_inter({ params })
    }
  };

  const internalHandleSearchDistrict = (e, city) => {
    const params = {
      meta: {
        filter: {
          keyword: e,
          id_city: city,
        },
        page: 1,
        pageSize: 100,
      },
    };
    PLACE.get_district({ params });
  };

  const internalHandleSearchDistrictRinjani = (e, city) => {
    if(PLACE.state.country_mandala.country_id == 1){
      const params = {
        meta: {
          filter: { keyword: e,id_city: city},
          page: 1,
          pageSize: 100,
        },
      };
      PLACE.get_district({ params })
    }else{
      const params = {
        meta: {
          filter: { keyword: e ,country_id: PLACE.state.country_mandala.country_id,parent_id: city},
          page: 1,
          pageSize: 100,
        },
      };
      PLACE.get_district_inter({ params })
    }
  };

  const internalHandleSearchVillage = (e, district) => {
    const params = {
      meta: {
        filter: {
          keyword: e,
          id_district: district,
        },
        page: 1,
        pageSize: 100,
      },
    };
    PLACE.get_village({ params });
  };

  const internalHandleFocusToFeatureCollection = (data = {}) => {
    if (data?.result?.flow?.flow_point) {
      initialMap.flyToBounds(
        L.geoJSON(data?.result?.flow?.flow_point).getBounds(),
        { maxZoom: 13, animate: true }
      );
    } else if (data?.result?.grid?.grid_density) {
      initialMap.flyToBounds(
        L.geoJSON(data?.result?.grid?.grid_density).getBounds(),
        { maxZoom: 13, animate: true }
      );
    } else if (data?.result?.poi?.features) {
      initialMap.flyToBounds(
        L.geoJSON(data?.result?.poi?.features).getBounds(),
        { maxZoom: 13, animate: true }
      );
    } else if (data?.type === 'THEMATIC') {
      if (!isEmpty(data?.advanceMap?.polygon_kecamatan)) {
        initialMap.flyToBounds(
          L.geoJSON(data?.advanceMap?.polygon_kecamatan).getBounds(),
          { maxZoom: 13, animate: true }
        );
      } else if (!isEmpty(data?.advanceMap?.polygon_kota)) {
        initialMap.flyToBounds(
          L.geoJSON(data?.advanceMap?.polygon_kota).getBounds(),
          { maxZoom: 13, animate: true }
        );
      } else if (!isEmpty(data?.advanceMap?.polygon_provinsi)) {
        initialMap.flyToBounds(
          L.geoJSON(data?.advanceMap?.polygon_provinsi).getBounds(),
          { maxZoom: 13, animate: true }
        );
      } else {
        initialMap.setZoom(1, { animate: true });
      }
    }
  };

  /**
   *
   * @param {Object} e
   * @param {string} e.groupName
   * @param {number} e.categoryId
   * @param {string} e.categoryIcon
   * @param {string} e.categoryName
   * @param {number[]} e.brandIds
   * @param {string[]} e.brandLabels
   * @param {string} e.industryName
   */
  const internalHandleCheckBrandStep1Poi = (e) => {
    setState({
      ...state,
      poiCategory: {
        ...state.poiCategory,
        key: e.categoryId,
        value: e.categoryName,
        icon: e.categoryIcon,
      },
      poiGroup: {
        ...state.poiGroup,
        value: e.groupName,
      },
      poiIndustry: {
        ...state.poiIndustry,
        value: e.industryName,
      },
      poiBrands: e.brandIds,
      poiBrandLabels: e.brandLabels,
    });
  };

  const listIndustriRinjani = () => {
    if(PLACE.state.country_mandala.country_id == 1){
      return datex.state?.industryList?.map((v) => {
        return {
          key: v.industry_id,
          label: v.industry_name,
          icon: getIcon('poi', v.industry_icon),
          status: v.industry_status,
        };
      })
    }else{
      return datex.state?.industryListRinjani?.map((v) => {
        return {
          key: v.id,
          label: v.name,
          icon: getIcon('poi', v.icon),
          status: "T",
        };
      })
    }
  }

  /**
   *
   * @param {string} params
   */
  const internalHandleOnSearchStep2Thematic = (params) => {
    datex?.getThematicSubcategoryList({
      id: state?.thematicCategory?.key,
      keyword: params,
    });
  };

  /**
   *
   * @param {string} params
   */
  const internalHandleOnSearchStep3Thematic = (params) => {
    datex?.getThematicItemList({
      id: state?.thematicSubCategory?.key,
      keyword: params,
    });
  };

  const listStep = [
    {
      number: 0,
      title: 'Layer',
      menu: ['TELCO', 'POI', 'THEMATIC','POI_RINJANI', 'THEMATIC_RINJANI', ''],
      info: `To create a layer, click 'Create Layer'. Layer will appear at 'Layer list'`,
      children: (
        <Fragment>
          <CardListItemDatex
            data={datex.state.dataLayerList}
            onChange={internalHandleOnChangeLayerPosition}
            onDelete={internalHandleOnDeleteLayer}
            onEdit={internalHandleOnEdit}
            onHighlight={internalHandleFocusToFeatureCollection}
            onSelectOptionChange={internalHandleOnSelectOptionChange}
            onSliderChange={internalHandleOnSliderChange}
            onVisible={internalHandleOnVisible}
          />
          <Popup
            body={{
              icon: 'confirmremove',
              desc: (
                <>
                  <h3 style={{ marginBottom: 0 }}>Are you sure you want to</h3>
                  <h3>Remove this layer?</h3>
                  <b>"{modalState.selectedCatchmentBy}"</b>
                  <p>This Process cannot be undone </p>
                </>
              ),
              iconHeight: '6rem',
              iconWidth: '6rem',
            }}
            cancelButton={true}
            cancelText='Cancel'
            footerButton={{
              leftClass: 'text-grey',
              rightClass: 'red',
            }}
            handler={{
              onHide: () => setModalState({ ...modalState, isShow: false }),
              onSubmit: () =>
                internalHandleConfirmDeleteLayer(modalState.selectedID),
            }}
            header={false}
            show={modalState.isShow}
            submitText='Remove'
          />
        </Fragment>
      ),
    },
    {
      number: 1,
      title: 'Select Data Category',
      menu: ['TELCO', 'POI', 'THEMATIC','THEMATIC_RINJANI','POI_RINJANI', ''],
      info: 'Choose a desired province and city/regency to explore, and then choose the data catchment type to determine if you want to see total or average data.',
      children: (
        <DatexTelcoStepOne
          activeKey={state?.type}
          data={{
            catchmentType: state.catchmentType,
            province: state.province,
            city: state.city,
            dateList: state.dateList,
            timeList: state.timeList,
            timeType: state.timeType,
            year: state.year,
            month: state.month,
            poiGroup: state?.poiGroup,
            thematicCategory: state?.thematicCategory,
            thematicSubCategory: state?.thematicSubCategory,
          }}
          industryList={datex.state?.industryList?.map((v) => {
            return {
              key: v.industry_id,
              label: v.industry_name,
              icon: getIcon('poi', v.industry_icon),
              status: v.industry_status,
            };
          })}
          industryListRinjani={listIndustriRinjani()}
          isLoadingSearchBrand={datex.state.status_GET_BRANDS === 'LOADING'}
          isLoadingThematicCategory={
            datex.state.status_GET_THEMATIC_CATEGORY_LIST === 'LOADING'
          }
          onChange={internalHandleOnChangeStep1}
          onSearchBrand={(e) => datex.doGetBrands(e)}
          onSelectBrands={internalHandleCheckBrandStep1Poi}
          onValidOrInvalid={internalHandleOnValidOrInvalid}
          province={state.province}
          setShowPoiResult={(e) => setShowSearchResult(e)}
          showPoiResult={showPoiSearchResult} //
          thematicList={datex.state?.thematicCategoryList?.map((v) => {
            // Filter untuk mengambil Demograpics dan Economics
            return {
              key: v?.category_id,
              label: v?.category_name,
              icon: v?.category_icon,
              status: v?.category_status
            };
          })}
          thematicListRinjani={datex.state?.thematicCategoryListRInjani?.map((v) => {
            // Filter untuk mengambil Demograpics dan Economics
            return {
              key: v?.category_id,
              label: v?.category_name,
              icon: v?.category_icon,
              status: v?.category_status
            };
          })}
        />
      ),
    },
    {
      number: 2,
      title: 'Select density data visualization',
      menu: ['TELCO'],
      info: 'Choose how you want to display the data on the map. You can choose between a grid map or a flow map.',
      children: (
        <DatexStepTelcoDataVisualization
          activeType={state.densityType}
          infoText={'Grid size is set by 1 km'}
          onChange={internalHandleOnChangeDensityTypeStep2}
          onValidOrInvalid={internalHandleOnValidOrInvalid}
          typeOptions={[
            {
              key: 'grid',
              label: 'Grid Map',
              geojson: PREVIEW_DATEX_GRID_DATA_CONSTANT,
            },
            {
              key: 'flow',
              label: 'Flow Map',
              geojson: PREVIEW_DATEX_MOVEMENT_DATA_CONSTANT,
            },
          ]}
        />
      ),
    },
    {
      number: 2,
      title: 'Select POI Category',
      menu: ['POI','POI_RINJANI'],
      info: 'Open a POI group to select a POI category to explore. You can only explore one category at a time',
      children: (
        <DatexStepPOICategoryCollection
          data={{
            poiIndustry: {
              key: datex.state?.industryDetail?.industry_id,
              icon: getIcon('poi', datex.state?.industryDetail?.industry_icon),
              label: datex.state?.industryDetail?.industry_name,
            },
            poiGroup: datex.state?.industryDetail?.groups?.map((v) => {
              return {
                key: v?.group_id,
                label: v?.group_name,
                icon: getIcon('poi', v?.group_icon),
                status: v?.group_status,
                categories: v?.categories?.map((w) => {
                  return {
                    key: w?.category_id,
                    label: w?.category_name,
                    icon: getIcon('poi', w?.category_icon),
                    criterias: w?.criterias || [],
                    brands: w?.brands || [],
                  };
                }),
              };
            }),
            poiCategory: { ...state.poiCategory },
          }}
          onCheckCategory={internalHandleOnChangeStep2Poi}
          onOpenEdit={internalHandleOpenEditPOI}
          onSetDefaultValue={({
            beforeSaveSelectedBrands,
            beforeSaveSelectedCriterias,
          }) => {
            setState({
              ...state,
              defaultValueBrands: beforeSaveSelectedBrands,
              defaultValueCriterias: beforeSaveSelectedCriterias,
            });
          }}
          onValidOrInvalid={internalHandleOnValidOrInvalid}
          openEdit={openEditPOI}
          selectedBrands={state.poiBrands}
          selectedCategories={state?.poiCategory}
          selectedCriterias={state.poiCriterias}
          selectedGroup={state?.poiGroup}
        />
      ),
    },
    {
      number: 2,
      title: 'Select Thematic Subcategory',
      menu: ['THEMATIC','THEMATIC_RINJANI'],
      info: 'Choose a subcategory you would like to explore. You can only explore one subcategory at a time.',
      children: (
        <DatextStepTwoThematic
          isLoading={
            datex.state.status_GET_THEMATIC_SUBCATEGORY_LIST === 'LOADING'
          }
          onChange={internalHandleOnChangeStep2Thematic}
          onSearch={internalHandleOnSearchStep2Thematic}
          onValidOrInvalid={internalHandleOnValidOrInvalid}
          selectedCategories={state.thematicCategory}
          selectedSubcategories={state.thematicSubCategory}
          thematicSubcategoryList={datex?.state?.thematicSubCategoryList?.[0]?.subcategories?.map(
            (v) => ({
              key: v?.subcategory_id,
              label: v?.subcategory_name,
              status: v?.subcategory_status
            })
          )}
        />
      ),
    },
    {
      number: 3,
      title: 'Select Area',
      menu: ['POI'],
      info: 'Use the dropdowns below to determine the area you would like to see the data items on. You can select from province level all the way to the village level.',
      children: (
        <DatexStepPOIAreaSelection
          brand={{
            key: state?.poiCategory?.key,
            value: state?.poiCategory?.value,
          }}
          category={{
            icon: state?.poiCategory?.icon,
            key: state?.poiCategory?.key,
            value: state?.poiCategory?.value,
          }}
          cityList={_.map(PLACE.state?.city_list_datex?.list, (v) => {
            return {
              value: v?.id_kota,
              label: v?.nama_kota,
              disabled: v?.status ? false: true
            };
          })}
          data={{
            province: state.poiProvince,
            city: state.poiCity,
            district: state.poiDistrict,
            village: state.poiVillage,
          }}
          districtList={_.map(PLACE.state?.district?.list, (v) => {
            return {
              value: v?.id_kecamatan,
              label: v?.nama_kecamatan,
            };
          })}
          onChange={internalHandleOnChangeStep3Poi}
          onSearchCity={(e, id) => internalHandleSearchCity(e, id)}
          onSearchDistrict={(e, id) => internalHandleSearchDistrict(e, id)}
          onSearchProvince={(e) => internalHandleSearchProvince(e)}
          onSearchVillage={(e, id) => internalHandleSearchVillage(e, id)}
          onValidInvalid={internalHandleOnValidOrInvalid}
          provinceList={_.map(PLACE.state?.province?.list, (v) => {
            return {
              value: v?.id_provinsi,
              label: v?.nama_provinsi,
              disabled: v?.status ? false: true
            };
          })}
          subcategory={{
            key: state?.poiGroup?.key,
            value: state?.poiGroup?.value,
          }}
          villageList={_.map(PLACE.state?.village?.list, (v) => {
            return {
              value: v?.id_desa,
              label: v?.nama_desa,
            };
          })}
        />
      ),
    },
    {
      number: 3,
      title: 'Select Area',
      menu: ['POI_RINJANI'],
      info: 'Use the dropdowns below to determine the area you would like to see the data items on. You can select from province level all the way to the village level.',
      children: (
        <DatexStepPOIAreaSelectionRinjani
          brand={{
            key: state?.poiCategory?.key,
            value: state?.poiCategory?.value,
          }}
          category={{
            icon: state?.poiCategory?.icon,
            key: state?.poiCategory?.key,
            value: state?.poiCategory?.value,
          }}
          cityList={_.map(PLACE.state?.city?.list, (v) => {
            const countryId = PLACE.state.country_mandala.country_id
            if(countryId == 1){
              return {
                value: v?.id_kota,
                label: v?.nama_kota,
                disabled: v?.status ? false: true
              };
            }else{
              return {
                value: v?.id,
                label: v?.name,
                disabled: false
              };
            }
          })}
          data={{
            province: state.poiProvince,
            city: state.poiCity,
            district: state.poiDistrict,
            village: state.poiVillage,
          }}
          districtList={_.map(PLACE.state?.district?.list, (v) => {
            const countryId = PLACE.state.country_mandala.country_id
            if(countryId == 1){
              return {
                value: v?.id_kecamatan,
                label: v?.nama_kecamatan,
              };
            }else{
              return {
                value: v?.id,
                label: v?.name,
              };
            }
          })}
          onChange={internalHandleOnChangeStep3PoiRinjani}
          onSearchCity={(e, id) => internalHandleSearchCityRinjani(e, id)}
          onSearchDistrict={(e, id) => internalHandleSearchDistrictRinjani(e, id)}
          onSearchProvince={(e) => internalHandleSearchProvinceRinjani(e)}
          onSearchVillage={(e, id) => internalHandleSearchVillage(e, id)}
          onValidInvalid={internalHandleOnValidOrInvalid}
          provinceList={_.map(PLACE.state?.province?.list, (v) => {
            const countryId = PLACE.state.country_mandala.country_id
            if(countryId == 1){
              return {
                value: v?.id_provinsi,
                label: v?.nama_provinsi,
                disabled: v?.status ? false: true
              };
            }else{
              return {
                value: v?.id,
                label: v?.name,
                disabled: false
              };
            }
          })}
          subcategory={{
            key: state?.poiGroup?.key,
            value: state?.poiGroup?.value,
          }}
          villageList={_.map(PLACE.state?.village?.list, (v) => {
            return {
              value: v?.id_desa,
              label: v?.nama_desa,
            };
          })}
        />
      ),
    },
    {
      number: 3,
      title: 'Select Thematic Data Items',
      menu: ['THEMATIC','THEMATIC_RINJANI'],
      info: 'Select data items you would like to see on the map. Here, you can select more than one data items. Or you can use the search bar to find a specific data item.',
      children: (
        <DatextStepThreeThematic
          isLoading={datex.state.status_GET_THEMATIC_ITEM_LIST === 'LOADING'}
          isMultiple={
            !STEP3SINGLE.includes(
              state?.thematicSubCategory?.label?.toLowerCase()
            )
          }
          onChange={internalHandleOnChangeStep3Thematic}
          onSearch={internalHandleOnSearchStep3Thematic}
          onValidOrInvalid={internalHandleOnValidOrInvalid}
          selectedCategories={state.thematicCategory}
          selectedItems={state.thematicItems}
          selectedSubcategories={state.thematicSubCategory}
          thematicItemList={
            state.thematicSubCategory?.label?.toLowerCase() === 'land value'
              ? datex?.state?.thematicItemList?.[0]?.subcategories?.[0]?.items
                  ?.filter(
                    (v) =>
                      !excludeLandValue.includes(v?.item_name?.toLowerCase())
                  )
                  ?.map((v) => ({
                    key: v?.item_id,
                    value: v?.item_name,
                    label: v?.item_name,
                  }))
              : datex?.state?.thematicItemList?.[0]?.subcategories?.[0]?.items?.map(
                  (v) => ({
                    key: v?.item_id,
                    value: v?.item_name,
                    label: v?.item_name,
                  })
                )
          }
        />
      ),
    },
    {
      number: 4,
      title: 'Select Area',
      menu: ['THEMATIC'],
      info: 'Use the dropdowns below to determine the area you would like to see the data items on. You can select from province level all the way to the village level.',
      children: (
        <DatextStepFourThematic
          cityList={_.map(PLACE.state?.city_list_datex?.list, (v) => {
            return {
              value: v?.id_kota,
              label: v?.nama_kota,
              code: v?.kode_kota,
              disabled: v?.status ? false: true
            };
          })}
          data={{
            province: state.thematicProvince,
            city: state.thematicCity,
            district: state.thematicDistrict,
            village: state.thematicVillage,
          }}
          districtList={_.map(PLACE.state?.district?.list, (v) => {
            return {
              value: v?.id_kecamatan,
              label: v?.nama_kecamatan,
              code: v?.kode_kecamatan,
            };
          })}
          onChange={internalHandleOnChangeStep4Thematic}
          onSearchCity={(e, id) => internalHandleSearchCity(e, id)}
          onSearchDistrict={(e, id) => internalHandleSearchDistrict(e, id)}
          onSearchProvince={(e) => internalHandleSearchProvince(e)}
          onSearchVillage={(e, id) => internalHandleSearchVillage(e, id)}
          onValidInvalid={internalHandleOnValidOrInvalid}
          provinceList={_.map(PLACE.state?.province?.list, (v) => {
            return {
              value: v?.id_provinsi,
              label: v?.nama_provinsi,
              code: v?.kode_provinsi,
              disabled: v?.status ? false: true
            };
          })}
          selectedCategories={state.thematicCategory}
          selectedSubcategories={state.thematicSubCategory}
          villageList={_.map(PLACE.state?.village?.list, (v) => {
            return {
              value: v?.id_desa,
              label: v?.nama_desa,
              code: v?.kode_desa,
            };
          })}
        />
      ),
    },
    {
      number: 4,
      title: 'Select Area',
      menu: ['THEMATIC_RINJANI'],
      info: 'Use the dropdowns below to determine the area you would like to see the data items on. You can select from province level all the way to the village level.',
      children: (
        <DatextStepFourThematicRinjani
          cityList={_.map(PLACE.state?.city?.list, (v) => {
            const countryId = PLACE.state.country_mandala.country_id
            if(countryId == 1){
              return {
                value: v?.id_kota,
                label: v?.nama_kota,
                code: v?.kode_kota,
                disabled: v?.status ? false: true
              };
            }else{
              return {
                value: v?.id,
                label: v?.name,
                code: v?.code,
                disabled: false
              };
            }
          })}
          data={{
            province: state.thematicProvince,
            city: state.thematicCity,
            district: state.thematicDistrict,
            village: state.thematicVillage,
          }}
          districtList={_.map(PLACE.state?.district?.list, (v) => {
            const countryId = PLACE.state.country_mandala.country_id
            if(countryId == 1){
              return {
                value: v?.id_kecamatan,
                label: v?.nama_kecamatan,
                code: v?.kode_kecamatan,
                disabled: v?.status ? false: true
              };
            }else{
              return {
                value: v?.id,
                label: v?.name,
                code: v?.code,
                disabled: false
              };
            }
          })}
          onChange={internalHandleOnChangeStep4ThematicRinjani}
          onSearchCity={(e, id) => internalHandleSearchCityRinjani(e, id)}
          onSearchDistrict={(e, id) => internalHandleSearchDistrictRinjani(e, id)}
          onSearchProvince={(e) => internalHandleSearchProvinceRinjani(e)}
          onSearchVillage={(e, id) => internalHandleSearchVillage(e, id)}
          onValidInvalid={internalHandleOnValidOrInvalid}
          provinceList={_.map(PLACE.state?.province?.list, (v) => {
            const countryId = PLACE.state.country_mandala.country_id
            if(countryId == 1){
              return {
                value: v?.id_provinsi,
                label: v?.nama_provinsi,
                code: v?.kode_provinsi,
                disabled: v?.status
              };
            }else{
              return {
                value: v?.id,
                label: v?.name,
                code: v?.code,
                disabled: false
              };
            }
          })}
          selectedCategories={state.thematicCategory}
          selectedSubcategories={state.thematicSubCategory}
          villageList={_.map(PLACE.state?.village?.list, (v) => {
            return {
              value: v?.id_desa,
              label: v?.nama_desa,
              code: v?.kode_desa,
            };
          })}
        />
      ),
    },
  ];

  useEffect(() => {
    const maxStep =
      listStep?.filter((v) => v?.menu?.includes(state?.type))?.length - 1;
    currentStep > 0 ? setIsBackShow(true) : setIsBackShow(false);
    if(currentStep > 1){
      PLACE.toggleAdvanceSearchDisabled(true)
      PLACE.toggleChangeCountryDisabled(true)
    }
    if(currentStep < maxStep){
      PLACE.toggleAdvanceSearchDisabled(false)
      PLACE.toggleChangeCountryDisabled(false)
    }
    currentStep <= maxStep && currentStep > 0
      ? setIsNextShow(true)
      : setIsNextShow(false);
    currentStep === maxStep && !!state?.type
      ? setIsShowButton(true)
      : setIsShowButton(false);
    currentStep === 0 && resetState();
  }, [currentStep]);

  useEffect(() => {
    datex.getLegendColorList();
    datex.getIndustryList();
    datex.getThematicCategoryList();
    datex.getDateList();
    return () => {
      legend.removeDataByPrefix('DATEX');
      legend.toggleOpenLegend(false);
      datex.resetState();
      setCurrentStep(0);
    };
  }, []);

  useEffect(() => {
    setCurrentStep(1);
    datex.getIndustryListRinjani({countryId:2});
    datex.getThematicCategoryListRinjani({countryId:PLACE.state.country_mandala.country_id,keyword:""});
  }, [PLACE.state.country_mandala?.country_id])

  useEffect(() => {
    setState({
      ...state,
      options: {
        value: state.options.value,
        data: datex?.state?.legendColors,
      },
    });
  }, [datex.state.legendColors]);

  useEffect(() => {
    if (datex.state.status_DO_ANALYZE === 'FAILED') {
      // START remap layervisibility
      // get list key id from dataLayerList
      const listKeyVisibility = [];
      datex.state?.dataLayerList.map((item) => {
        listKeyVisibility.push(`DATEX_${item?.id}`);
      });

      mainmap.state.layerVisibilityList.map((item) => {
        if (
          item.key.includes('DATEX_') &&
          listKeyVisibility.indexOf(item.key) < 0
        ) {
          mainmap.removeLayer(item.key);
        }
      });
      // END remop layervisibility

      let message = 'Warning';
      if (datex.state.statusCode_DO_ANALYZE === 400) {
        message = 'Bad Request';
      } else if (
        datex.state.statusCode_DO_ANALYZE === 404 ||
        datex.state.statusCode_DO_ANALYZE === 500 ||
        datex.state.statusCode_DO_ANALYZE === 524 ||
        datex.state.statusCode_DO_ANALYZE === 408
      ) {
        message = 'Data is Not Available';
      }
      notification.warning({
        message: message,
        description: datex.state?.statusMessage_DO_ANALYZE,
        placement: 'bottomRight',
        style: { zIndex: 99999999 },
      });
    }
    if (datex.state.status_DO_ANALYZE === 'SUCCESS') {
      legend.isLoadingData(true);
      legend.removeDataByPrefix('DATEX');
      mainmap.state.layerVisibilityList.some((item) => {
        if (item.title === 'DATEX_') {
          let layerTitle = '';
          let description = [];
          const idLayer = Number(item.key.replace('DATEX_', ''));
          const datexLayer = _.find(
            datex.state.dataLayerList,
            (layer) => layer.id === idLayer
          );
          if (datexLayer.type === 'TELCO') {
            layerTitle = 'Telco Data';
            description = [
              datexLayer?.result?.layerSummary?.area,
              datexLayer?.result?.layerSummary?.date,
              datexLayer?.result?.layerSummary?.time?.replaceAll(
                ':00:00',
                ':00'
              ),
            ]?.filter((item) => item || item !== '');
          } else if (datexLayer.type === 'POI' || datexLayer.type === 'POI_RINJANI') {
            layerTitle = 'POI';
            description = [
              datexLayer?.poiIndustry?.value,
              datexLayer?.poiGroup?.value,
              datexLayer?.poiCategory?.value,
            ];
          } else if (datexLayer.type === 'THEMATIC' || state?.type === 'THEMATIC_RINJANI') {
            layerTitle = 'THEMATIC';
            description = [
              datexLayer.thematicCategory.label,
              datexLayer.thematicSubCategory.label,
            ];
          }
          mainmap.updateLayer({
            key: item.key,
            title: layerTitle,
            isActive: item.isActive,
            opacity: (datexLayer.type === 'POI' || datexLayer.type === "POI_RINJANI") ? 1 : item.opacity,
            description: description,
          });
        }
      });
    }
  }, [datex.state.status_DO_ANALYZE]);

  useEffect(() => {
    if (datex.state?.status_DO_ANALYZE === 'SUCCESS') {
      legend.isLoadingData(true);
      const tempLegendlist = internalMappingDatexLayerList();
      legend.pushData({
        data: uniqBy([...tempLegendlist], 'key'),
      });
      legend.isLoadingData(false);
      legend.toggleOpenLegend(true);
      ['provinsi', 'kota', 'kecamatan', 'desa'].forEach((placeType) =>
        PLACE.setGeojson({ [placeType]: null })
      );
    }
    if (datex.state?.status_DO_ANALYZE === 'FAILED') {
      legend.isLoadingData(false);
    }
    datex.resetStatus();
  }, [datex.state?.dataLayerList]);

  useEffect(() => {
    let newData = datex.state.dataLayerList.map((el) => el);
    mainmap.state.layerVisibilityList.map((item) => {
      if (item.key.includes('DATEX_')) {
        const idLayer = Number(item.key.replace('DATEX_', ''));
        newData = newData.map((el) =>
          el?.id === idLayer ? { ...el, isShowVisibility: item?.isActive } : el
        );
      }
    });
    datex.replaceFeatureToDataLayer({ data: newData });
  }, [mainmap.state.layerVisibilityList]);

  useEffect(() => {
    setBackDisabled(
      state.type === 'POI' &&
        currentStep === 3 &&
        PLACE.state.advance?.status_GET === 'LOADING'
        ? true
        : false
    );
  }, [PLACE.state.advance]);

  return (
    <React.Fragment>
      <LayoutDatexSidebarWrapper
        cancelText={internalCancelTextGet()}
        children={
          mainmap.state.activeMenu === 'DATEX' ? (
            listStep?.find(
              (v) => v?.menu?.includes(state?.type) && v?.number === currentStep
            )?.children
          ) : (
            <></>
          )
        }
        className=''
        height='calc(100vh - 8.0262rem)'
        isActive={mainmap.state.activeMenu === 'DATEX'}
        isAddDisabled={datex.state.dataLayerList.length > 4}
        isBackDisabled={isBackDisabled}
        isBackShow={isBackShow}
        isNextDisabled={isNextDisabled}
        isNextShow={isNextShow}
        isShowButton={isShowButton}
        nextText={internalNextTextGet()}
        onAddLayer={internalHandleOnAddLayer}
        onBack={internalHandleBack}
        // TODO: clear step
        onClose={internalHandleOnCloseDatex}
        onMouseEnter={internalHandleOnMouseEnter}
        onMouseLeave={internalHandleOnMouseLeave}
        onNext={internalHandleNext}
        step={listStep?.find(
          (v) => v?.menu?.includes(state?.type) && v?.number === currentStep
        )}
        style={{
          right: '0',
          width: mainmap.state.activeMenuWidth,
          top: 130,
        }}
      />
      <GeoJsonDatexContainer />
    </React.Fragment>
  );
};

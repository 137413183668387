import { Button, Svg } from '@bvt-atoms';
import { IconClose } from '@bvt-icons';
import Modal from 'antd/lib/modal/Modal';
import React, { useState } from 'react';
import { CardMoreInformation } from '../CardMoreInformation';
import './CardDatexThematicDataDetail.scss';

/**
 * @copyright PT Bhumi Varta Technology
 * @author Renta <renta.yustika@bvarta.com>
 * @param {Object} props
 * @param {Array<{key:string,value:string}>} props.data
 */

export const CardDatexThematicDataDetail = (props) => {
  const { data } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <div className='CardDatexThematicDataDetail__container'>
      <Button
        className='CardDatexThematicDataDetail__btn-summary'
        onClick={() => setIsModalOpen(true)}
      >
        <Svg
          className='CardDatexThematicDataDetail__btn-summary-img'
          src='ic-info'
        />
        <span className='CardDatexThematicDataDetail__btn-summary-text'>
          Layer Summary
        </span>
      </Button>

      <CardMoreInformation
        data={data}
      />

      <Modal
        centered
        className='CardDatexThematicDataDetail__modal'
        closeIcon={
          <IconClose
            background='white'
            className='feature-map LayoutDatexSidebarWrapper__header__title__button'
            onClick={() => setIsModalOpen(false)}
          />
        }
        footer={null}
        open={isModalOpen}
        style={{ zIndex: '999999 !important' }}
        title={'Layer Summary'}
      >
        <CardMoreInformation
          className={'CardDatexThematicDataDetail__modal-more'}
          data={data}
        />
      </Modal>
    </div>
  );
};

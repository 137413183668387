import { useDispatch, useSelector } from 'react-redux';
import { DATEX_ACTION } from '../store/datex.slice';
import {
  doAnalyzeTelcoData,
  getLegendColor,
  doGetListDate,
  doGetIndustryList,
  doGetIndustryDetail,
  doAnalyzeDatexPoi as doAnalyzePoi,
  doGetThematicCategoryList,
  doGetThematicCategoryListRinjani,
  doGetThematicSubCategoryList,
  doGetThematicItemList,
  getBrands,
  doAnalyzeDatexThematic as doAnalyzeThematic,
  doGetThematicSubCategoryListRinjani,
  doGetIndustryListRinjani,
} from '../store/datex.asyncAction';

/**
 * @copyright PT Bhumi Varta Techonology
 * @author Renta<renta.yustika@bvarta.com>
 */

/**
 * @typedef Brand
 * @property {number} brand.brand_id
 * @property {null} brand.brand_description
 * @property {null} brand.brand_icon
 * @property {string} brand.brand_name
 * @property {string} brand.brand_code
 */

/**
 * @typedef Category
 * @property {number} id
 * @property {string} name
 * @property {string} code
 * @property {string} description
 * @property {string} icon
 * @property {Array<Brand>} brands
 * @property {string} group_name
 * @property {string} industry_name
 */

export const useDatex = () => {
  const state = useSelector(
    /**
     * @param {ReturnType<typeof import('../../../redux/rootStore').rootStore.getState>} state
     */
    (state) => state.features.DATEX
  );

  const dispatch = useDispatch();

  /**
   *
   * @param {object} body
   * @param {import('../store/datex.slice').TDataLayerObject} body.data
   */
  const addFeatureToDataLayer = (body) => {
    dispatch(DATEX_ACTION.addFeatureToDataLayer(body));
  };

  // Analisa DATEX
  /**
   * @description For DATEX Analysis
   * @param {object} param
   * @param {number} param.id
   * @param {string} param.name
   * @param {number} param.step
   */
  const doAnalyzeDatexTelcoData = (param) => {
    dispatch(doAnalyzeTelcoData(param));
  };

  // Analisa DATEX POI
  /**
   * @description For DATEX Analysis
   * @param {object} param
   * @param {number} param.id
   * @param {string} param.name
   * @param {number} param.step
   */
  const doAnalyzeDatexPoi = (param) => {
    dispatch(doAnalyzePoi(param));
  };


  // Analisa DATEX THEMATIC
  /**
   * @description For DATEX Analysis
   * @param {object} param
   * @param {number} param.id
   * @param {"red" | "blue" | "yellow" | "gray" | "random"} param.color
   * @param {number} param.attribute
   * @param {Array<number>} param.attribute
   * @param {number} param.region
   * @param {'province' | 'regency' | 'district' | 'village'} _body.levelData
   * @param {'national' | 'province' | 'regency' | 'district'} _body.levelRegion
   * @param {number} _body.country_id
   */
  const doAnalyzeDatexThematic = (param) => {
    dispatch(doAnalyzeThematic(param));
  };

  /**
   *
   * @param {object} param
   * @param {number} param.id
   */
  const removeFeatureFromDatalayer = (param) => {
    dispatch(DATEX_ACTION.removeFeatureFromDatalayer(param));
  };
  /**
   * @description For get legend color list
   */
  const getLegendColorList = () => {
    dispatch(getLegendColor());
  };
  /**
   *
   * @param {object} param
   * @param {Array<import('../store/datex.slice').TDataLayerObject>} param.arrOfData
   * @param {string} param.sortKey
   */
  const sortFeatureToDataLayer = (param) => {
    dispatch(DATEX_ACTION.sortFeatureToDataLayer(param));
  };

  /**
   *
   * @param {object} param
   * @param {Array<import('../store/datex.slice').TDataLayerObject>} param.data
   */
  const replaceFeatureToDataLayer = (param) => {
    dispatch(DATEX_ACTION.replaceFeatureToDataLayer(param));
  };

  const resetState = () => {
    dispatch(DATEX_ACTION.resetState());
  };

  const resetStatus = () => {
    dispatch(DATEX_ACTION.resetStatus());
  };

  const getDateList = () => {
    dispatch(doGetListDate());
  };

  const getIndustryList = () => {
    dispatch(doGetIndustryList());
  };

  /**
   * @param {Object} param  
   * @param {String|Number} param.countryId
   */
  const getIndustryListRinjani = (param) => {
    dispatch(doGetIndustryListRinjani({countryId:param.countryId}));
  };

  /**
   * @param {Object} param
   * @param {String|Number} param.id
   * @param {String|Number} param.countryId
   */
  const getGroupCategoryListByIndustry = (param) => {
    dispatch(doGetIndustryDetail({ id: param.id,countryId:param.countryId }));
  };

  /**
   * @param {Object} param
   * @param {String} param.keyword
   */
  const getThematicCategoryList = (param) => {
    dispatch(doGetThematicCategoryList(param?.keyword ?? ''));
  };

  /**
   * @param {Object} param
   * @param {String} param.keyword
   * @param {String} param.countryId
   */
  const getThematicCategoryListRinjani = (param) => {
    dispatch(doGetThematicCategoryListRinjani(param));
  };

  /**
   * @param {Object} param
   * @param {String|Number} param.id
   * @param {String} param.keyword
   */
  const getThematicSubcategoryList = (param) => {
    dispatch(
      doGetThematicSubCategoryList({
        id: param.id,
        keyword: param?.keyword ?? '',
      })
    );
  };

  /**
   * @param {Object} param
   * @param {String|Number} param.id
   * @param {String} param.keyword
   * @param {String} param.country_id
   */
  const getThematicSubcategoryListRinjani = (param) => {
    dispatch(
      doGetThematicSubCategoryListRinjani({
        id: param.id,
        keyword: param?.keyword ?? '',
        countryId : param.countryId
      })
    );
  };

  /**
   * @param {Object} param
   * @param {String|Number} param.id
   * @param {String} param.keyword =
   */
  const getThematicItemList = ({id,keyword='',countryId=1}) => {
    dispatch(
      doGetThematicItemList({ id: id, keyword: keyword, countryId : countryId})
    );
  };

  const mapperBrandsByCategory = () => {
    /**
     * @type {Array<Category>}
     */
    const categoriesWithBrands = [];

    state.brands.forEach(brand => {
      const category = categoriesWithBrands.find(c => c.id === brand.category.category_id);
      const newBrand = {
        id: brand.brand_id,
        name: brand.brand_name,
      };

      if (!category) {
        const newCategory = {
          id: brand.category.category_id,
          name: brand.category.category_name,
          code: brand.category.category_code,
          description: brand.category.category_description,
          icon: brand.category.category_icon,
          group_name: brand.category?.v3_tbl_group?.group_name,
          industry_name: brand.category?.v3_tbl_group?.v3_tbl_industry?.industry_name,
          brands: [newBrand],
        };

        categoriesWithBrands.push(newCategory);
      } else {
        category.brands.push(newBrand);
      }
    });

    return categoriesWithBrands;
  };

  /**
   * @param {string} name
   */
  const doGetBrands = (name) => {
    const query = { meta: { filter: { brand_name: name } } };
    dispatch(getBrands(query));
  };

  return {
    state,
    addFeatureToDataLayer,
    removeFeatureFromDatalayer,
    sortFeatureToDataLayer,
    replaceFeatureToDataLayer,
    doAnalyzeDatexTelcoData,
    doAnalyzeDatexPoi,
    resetState,
    resetStatus,
    getLegendColorList,
    getDateList,
    getIndustryList,
    getIndustryListRinjani,
    getGroupCategoryListByIndustry,
    getThematicCategoryList,
    getThematicCategoryListRinjani,
    getThematicSubcategoryList,
    getThematicSubcategoryListRinjani,
    getThematicItemList,
    mapperBrandsByCategory,
    doGetBrands,
    doAnalyzeDatexThematic,
  };
};

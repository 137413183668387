import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import './CatchmentTypeRadio.scss';

/**
 * @copyright PT Bhumi Varta Technology
 * @author Renta<renta.yustika@bvarta.com>
 */

/**
 * @description For data category object
 * @typedef TCategoryDataObject
 * @property {string|number} key
 * @property {string} label
 * @property {any} icon
 */

/**
 * @copyright PT Bhumi Varta Techonology
 * @author Renta<renta.yustika@bvarta.com>
 * @description Button Group DATEX
 * @param {object} props
 * @param {Array<TCategoryDataObject>} props.data
 * @param {(key:string)=>void} props.onChange
 * @param {string} props.activeKey
 */

export const CatchmentTypeRadio = (props) => {
  const { data, activeKey, onChange = () => {} } = props;
  const [state, setState] = useState({
    activeKey: '',
  });

  useEffect(() => {
    if (activeKey) {
      setState({ ...state, activeKey: activeKey });
    }
  }, [activeKey]);

  const handleChangeActive = (e) => {
    setState({
      ...state,
      activeKey: e?.key,
    });
    onChange(e);
  };

  return (
    <div className='CatchmentTypeRadio__container'>
      {_.map(data || [], (v, i) => {
        return (
          <div
            className={`CatchmentTypeRadio__item ${
              state?.activeKey === v?.key
                ? 'CatchmentTypeRadio__item--active'
                : ''
            }`}
            key={i}
            onClick={(e) => {
              e.stopPropagation();
              handleChangeActive(v);
            }}
          >
            {v?.icon}
            <span>{v?.label}</span>
          </div>
        );
      })}
    </div>
  );
};

import React, { useEffect, useState, useRef } from 'react';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import L from 'leaflet';
import _, { isArray } from 'lodash';
import * as turf from '@turf/turf';

import { IconInformation } from '@bvt-assets/icon/icon-information';
import { IconChecklist } from '@bvt-assets/icon/icon-checklist';
import { useMainMap } from '@bvt-features/mainmap/mainmap/hook/useMainMap';
import { DEFAULT_MAP_OPTIONS_DATA_CONSTANT } from '@bvt-features/mainmap/mainmap/constant/DEFAULT_MAP_OPTIONS_DATA_CONSTANT';
import './DatexStepTelcoDataVisualization.scss';
import { PREVIEW_DATEX_AREA_DATA_CONSTANT } from '@bvt-features/datex/constant/PREVIEW_DATEX_AREA_DATA_CONSTANT';
/**
 * @author randa<m.randa@bvarta.com>
 * @param {object} props
 * @param {string} props.infoText
 * @param {Array<{key:string,label:string,geojson:string}>} props.typeOptions for array of button to visualize the flow map
 * @param {Array<string>} props.activeType active type of the options, array of key that you register on typeOptions
 * @param {(e:boolean)=>void} props.onValidOrInvalid
 */
export const DatexStepTelcoDataVisualization = ({
  infoText,
  activeType,
  typeOptions,
  onValidOrInvalid,
  onChange,
}) => {
  const [state, setState] = useState([]);
  const mainmap = useMainMap();
  const tileRef = useRef(null);

  useEffect(() => {
    if (isArray(activeType)) {
      setState(activeType);
    }
  }, [activeType]);

  useEffect(() => {
    if (tileRef.current) {
      tileRef.current.setUrl(
        _.find(
          mainmap.state.baseMapList,
          (v) => v.key === mainmap.state.selectedBaseMap
        ).value
      );
    }
  }, [mainmap.state.selectedBaseMap]);

  useEffect(() => {
    if (state.length > 0) {
      onValidOrInvalid && onValidOrInvalid(true);
    } else {
      onValidOrInvalid && onValidOrInvalid(false);
    }
  }, [state]);

  const internalHandleOnchange = (param) => {
    let nState = _.filter(state, (vs) => vs !== param);
    let nIdx = _.findIndex(state, (vs) => vs === param);
    if (nIdx > -1) {
      setState(nState);
      typeof onChange === 'function' && onChange(nState);
    } else {
      setState([...state, param]);
      typeof onChange === 'function' && onChange([...state, param]);
    }
  };

  const internalMapGeojsonStyle = (param) => {
    const { v, layer } = param;
    if (layer instanceof L.Layer && v.properties.gid) {
      layer.bringToBack();
      let randomizer = Math.random() * (0.5 - 0.1) + 0.1;
      layer.setStyle({
        stroke: 0.1,
        color: '#4BA09D',
        fillColor: '#4BA09D',
        fillOpacity: randomizer,
        opacity: randomizer,
      });
    }
    if (layer instanceof L.Layer && v.properties.circle) {
      layer.bringToFront();
      layer.setStyle({
        color: '#FF6700',
        fillColor: '#FF6700',
        fillOpacity: 1,
        opacity: 1,
      });
    }
  };

  const internalCenterOfMiniMap = turf
    .centroid(PREVIEW_DATEX_AREA_DATA_CONSTANT.features[0])
    .geometry.coordinates.reverse();

  const internalBaseMapUrl = _.find(
    mainmap.state.baseMapList,
    (v) => v.key === mainmap.state.selectedBaseMap
  )?.value;

  return (
    <div className='feature-datex DatexStepTelcoDataVisualization'>
      <div className='DatexStepTelcoDataVisualization__heading-wrapper'>
        <div className='DatexStepTelcoDataVisualization__info-text'>
          {infoText || '-'}
        </div>
        <div className='DatexStepTelcoDataVisualization__info-icon'>
          <IconInformation />
        </div>
      </div>
      <div className='DatexStepTelcoDataVisualization__middle'>
        <div className='DatexStepTelcoDataVisualization__btn-group'>
          {typeOptions &&
            typeOptions.map((v, idx) => (
              <button
                className={`DatexStepTelcoDataVisualization__btn ${
                  _.findIndex(state, (sv) => sv === v.key) > -1 &&
                  'DatexStepTelcoDataVisualization__btn--active'
                }`}
                key={v.key + idx}
                onClick={() => internalHandleOnchange(v.key)}
              >
                <div
                  className={'DatexStepTelcoDataVisualization__btn-checkbox'}
                >
                  <IconChecklist />
                </div>
                <span className='DatexStepTelcoDataVisualization__btn-text'>
                  {v.label}
                </span>
              </button>
            ))}
        </div>
        <div className='DatexStepTelcoDataVisualization__map-wrapper'>
          <div className='DatexStepTelcoDataVisualization__map-text'>
            Map Preview
          </div>
          <MapContainer
            {...DEFAULT_MAP_OPTIONS_DATA_CONSTANT}
            center={internalCenterOfMiniMap}
            dragging={false}
            scrollWheelZoom={false}
            style={{ width: '100%', height: '256px' }}
            zoom={12}
            zoomControl={false}
          >
            <TileLayer
              keepBuffer={5}
              opacity={1}
              ref={tileRef}
              tileSize={256}
              url={internalBaseMapUrl}
            />
            {typeOptions &&
              typeOptions.map((tv, idx) =>
                _.findIndex(state, (sv) => sv === tv.key) > -1 ? (
                  <GeoJSON
                    data={tv.geojson}
                    key={idx}
                    onEachFeature={(v, layer) =>
                      internalMapGeojsonStyle({ v, layer })
                    }
                  />
                ) : (
                  <></>
                )
              )}
            <GeoJSON
              data={PREVIEW_DATEX_AREA_DATA_CONSTANT}
              style={{
                color: '#748DA6',
                stroke: 1,
                fillColor: '#748DA6',
                fillOpacity: 0.3,
                opacity: 0.3,
              }}
            />
          </MapContainer>
        </div>
      </div>
    </div>
  );
};

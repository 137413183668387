/* eslint-disable indent */
import { IconLock } from '@bvt-assets/icon/icon-lock';
import { useUser } from '@bvt-features/account/hooks';
import { AccountCard } from '@bvt-features/auth/component/AccountCard/AccountCard';
import { AccountNoteInfo } from '@bvt-features/auth/component/AccountNoteInfo';
import { PasswordStrengthBar } from './components/PasswordStrengthBar';
import { passwordScore } from '@bvt-features/auth/helper';
import { useAuth } from '@bvt-features/auth/hooks';
import { Button, Col, Divider, Input, Row, Switch } from 'antd';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import './SecurityAndLoginContainer.scss';
import * as Yup from 'yup';
import { isString } from 'lodash';

/**
 * @company PT Bhumi Varta Technology
 * @author Renta<renta.yustika@bvarta.com>
 */

export const SecurityAndLoginContainer = () => {
  const auth = useAuth();
  const user = useUser();
  const [state, setState] = useState({
    alwaysAuth: undefined,
  });
  const FORMIK = useFormik({
    initialValues: {
      currentPassword: undefined,
      newPassword: undefined,
      retypePassword: undefined,
    },
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      currentPassword: Yup.string().required('Current password is required'),
      newPassword: Yup.string()
        .required('New password is required')
        .test(
          'password-value',
          'New passoword must be different',
          (value, ctx) => value !== ctx.parent.currentPassword
        )
        .test(
          'passowrd-str',
          'Password strength is not strong enough.',
          (value) => passwordScore(value).score > 1
        ),
      retypePassword: Yup.string()
        .required('Please retype your new password')
        .test(
          'password-value',
          `Password confirmation doesn't match`,
          (value, ctx) => value === ctx.parent.newPassword
        ),
    }),
    onSubmit: (p) => {
      user.updatePassword({ password: p?.newPassword });
    },
  });
  const newPassScore = passwordScore(FORMIK.values.newPassword);
  const retypePassScore = passwordScore(FORMIK.values.retypePassword);

  const internalHandleSwitchAuth = (e) => {
    setState({
      ...state,
      alwaysAuth: e,
    });
  };

  const internalHandleUpdatePassword = () => {
    FORMIK.submitForm();
  };

  useEffect(() => {
    return () => {
      user.resetStatus();
    };
  }, []);

  useEffect(() => {
    if (user.state?.status_PUT === 'SUCCESS') {
      FORMIK.resetForm();
    }
  }, [user.state?.status_PUT]);

  return (
    <Row className='SecurityAndLoginContainer' gutter={[16, 16]}>
      <Col className='SecurityAndLoginContainer--content' span={10}>
        <div className='SecurityAndLoginContainer--info'>
          Some changes can only be made once in a long period of time, so be
          careful before making changes to your data, if a problem occurs please
          contact the admin center.
        </div>
        <AccountCard
          content={
            <div className='SecurityAndLoginContainer--item'>
              <div className='SecurityAndLoginContainer--form'>
                <div className='SecurityAndLoginContainer--form__label'>
                  Email
                </div>
                <div className='SecurityAndLoginContainer--form__input'>
                  <Input
                    className='SecurityAndLoginContainer--form__input custom-input'
                    disabled
                    suffix={<IconLock />}
                    value={auth.state?.data?.user?.email}
                  />
                </div>
              </div>
              <AccountNoteInfo
                bordered
                content={
                  <div>
                    <strong>Please note:</strong> we will send authentication to
                    your email if we detect login activity using your account on
                    a different device.
                  </div>
                }
              />
              <div className='SecurityAndLoginContainer--confirmation'>
                <div className='SecurityAndLoginContainer--confirmation__wrapper'>
                  <div className='SecurityAndLoginContainer--confirmation__label'>
                    Authentication
                  </div>
                  <div className='SecurityAndLoginContainer--confirmation__input'>
                    <span>{state?.alwaysAuth ? 'Never' : 'Always'}</span>
                    <Switch
                      checked={state?.alwaysAuth}
                      className='SecurityAndLoginContainer--confirmation__input-switch'
                      onChange={internalHandleSwitchAuth}
                    />
                  </div>
                </div>
                <Divider className='SecurityAndLoginContainer--confirmation__divider' />
              </div>
            </div>
          }
          title='Security'
        />
        <AccountCard
          content={
            <div className='SecurityAndLoginContainer--item'>
              <div className='SecurityAndLoginContainer--form'>
                <div className='SecurityAndLoginContainer--form__label'>
                  Current Password
                </div>
                <div className='SecurityAndLoginContainer--form__input'>
                  <Input.Password
                    autoComplete='new-password'
                    allowClear
                    className='SecurityAndLoginContainer--form__input custom-input'
                    iconRender={(visible) =>
                      !visible ? (
                        <button className='suffix-show'>Show</button>
                      ) : (
                        <button className='suffix-show'>Hide</button>
                      )
                    }
                    onChange={(e) =>
                      FORMIK.setFieldValue('currentPassword', e?.target?.value)
                    }
                    placeholder='Current password'
                    value={FORMIK.values?.currentPassword}
                  />
                  {FORMIK.errors?.currentPassword &&
                    isString(FORMIK.values.currentPassword) && (
                      <span className='SecurityAndLoginContainer--form__error-message'>
                        {FORMIK.errors?.currentPassword}
                      </span>
                    )}
                </div>
              </div>
              <div className='SecurityAndLoginContainer--item'>
                <div className='SecurityAndLoginContainer--form'>
                  <div className='SecurityAndLoginContainer--form__label'>
                    New Password
                  </div>
                  <div className='SecurityAndLoginContainer--form__input'>
                    <Input.Password
                      allowClear
                      className='SecurityAndLoginContainer--form__input custom-input'
                      iconRender={(visible) =>
                        !visible ? (
                          <button className='suffix-show'>Show</button>
                        ) : (
                          <button className='suffix-show'>Hide</button>
                        )
                      }
                      onChange={(e) =>
                        FORMIK.setFieldValue('newPassword', e?.target?.value)
                      }
                      placeholder='Enter password'
                      value={FORMIK.values?.newPassword}
                    />
                    {FORMIK.errors?.newPassword &&
                      isString(FORMIK.values.newPassword) && (
                        <span className='SecurityAndLoginContainer--form__error-message'>
                          {FORMIK.errors?.newPassword}
                        </span>
                      )}
                    <PasswordStrengthBar
                      data={{
                        value: newPassScore?.percentage,
                        label: newPassScore?.str,
                        color: newPassScore?.color,
                      }}
                      hideInfo={FORMIK.values.newPassword}
                    />
                  </div>
                </div>
              </div>
              <div className='SecurityAndLoginContainer--item'>
                <div className='SecurityAndLoginContainer--form'>
                  <div className='SecurityAndLoginContainer--form__label'>
                    Retype your Password
                  </div>
                  <div className='SecurityAndLoginContainer--form__input'>
                    <Input.Password
                      allowClear
                      className='SecurityAndLoginContainer--form__input custom-input'
                      iconRender={(visible) =>
                        !visible ? (
                          <button className='suffix-show'>Show</button>
                        ) : (
                          <button className='suffix-show'>Hide</button>
                        )
                      }
                      onChange={(e) =>
                        FORMIK.setFieldValue('retypePassword', e?.target?.value)
                      }
                      placeholder='Enter password'
                      value={FORMIK.values?.retypePassword}
                    />
                    {FORMIK.errors?.retypePassword &&
                      isString(FORMIK.values.retypePassword) && (
                        <span className='SecurityAndLoginContainer--form__error-message'>
                          {FORMIK.errors?.retypePassword}
                        </span>
                      )}
                  </div>
                    <AccountNoteInfo
                      bordered
                      content={
                        <div>
                          The password must be contain uppercase, lowercase,
                          alphanumberic, and special character with 6-20
                          characters.
                        </div>
                      }
                    />
                </div>
              </div>
              <div className='SecurityAndLoginContainer--item'>
                <Button
                  className='SecurityAndLoginContainer--button'
                  disabled={Object.keys(FORMIK.errors)?.length > 0}
                  loading={user.state?.status_PUT === 'LOADING'}
                  onClick={internalHandleUpdatePassword}
                >
                  Save Changes
                </Button>
              </div>
            </div>
          }
          title='Password'
        />
      </Col>
    </Row>
  );
};

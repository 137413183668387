import { createSlice, current } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  doAnalyzeTelcoData,
  getLegendColor,
  doGetListDate,
  doGetIndustryList,
  doGetIndustryDetail,
  doAnalyzeDatexPoi,
  doGetThematicCategoryList,
  doGetThematicSubCategoryList,
  doGetThematicItemList,
  getBrands,
  doAnalyzeDatexThematic,
  doGetThematicCategoryListRinjani,
  doGetThematicSubCategoryListRinjani,
  doGetIndustryListRinjani,
} from './datex.asyncAction';

/**
 * @copyright PT Bhumi Varta Techonology
 * @author Renta<renta.yustika@bvarta.com>
 */

/**
 * @description For dataLayer object
 * @typedef TDataLayerObject
 * @type {Object}
 * @property {number} id
 * @property {number} sort
 * @property {'THEMATIC'|'POI'|'TELCO'|'YOUR_DATA'} type
 * @property {number} province id of province
 * @property {Object} city id of city
 * @property {Object} month number of month
 * @property {Object} year number of year
 * @property {Object} dateList list selected date
 * @property {'total'|'average'} catchmentType
 * @property {Array} timeList list seleceted time
 * @property {Array} densityType list of density type ['grid', 'flow']
 * @property {'IDLE'|'SUCCESS'|'FAILED' | 'LOADING'} status_ANALYZE
 * @property {String} timeType
 * @property {Object} poiIndustry list of option legend
 * @property {Object} poiGroup
 * @property {Object} poiCategory
 * @property {Array} poiBrands
 * @property {Array} poiBrandLabels
 * @property {Array} poiCriterias
 * @property {Array} poiCriteriaLabels
 * @property {Number} poiProvince
 * @property {Number} poiCity
 * @property {Number} poiDistrict
 * @property {Number} poiVillage
 * @property {Object} poiArea
 * @property {Object} thematicCategory
 * @property {Number} thematicCategory.id
 * @property {String} thematicCategory.label
 * @property {Object} thematicSubCategory
 * @property {Number} thematicSubCategory.id
 * @property {String} thematicSubCategory.label
 * @property {String} thematicSubCategory.additional
 * @property {Boolean} thematicSubCategory.chart
 * @property {String} thematicSubCategory.symbology
 * @property {String} thematicSubCategory.area_level
 * @property {String} thematicSubCategory.select_item
 * @property {String} thematicSelectedYearIndex
 * @property {Array<{id: Number, label: string}>} thematicItems
 * @property {Array} defaultValueBrands
 * @property {Array} defaultValueCriterias
 * @property {Object} advanceMap

 *
 *  value of seleceted option data
 * @property {Object} options
 * @property {Array} options.data list of option legend
 * @property {string} options.value value of seleceted option data
 * @property {Object} result
 * @property {Object} result.grid
 * @property {Object} result.flow
 * @property {Object} result.poi
 * @property {Object} result.poi.features
 * @property {Object} result.poi.counter
 * @property {Array} result.layerSummary
 * @property {Boolean} isShowVisibility
 * @property {Array} advanceMap

/**
 * @description For dataLayer object
 * @typedef TLegendColor
 * @type {Object}
 * @property {String} class_name
 * @property {String} name
 * @property {String} hex
 */

/**
 * @description For Industry Detail
 * @typedef Industry
 * @property {number} industry_id
 * @property {string} industry_name
 * @property {string} industry_code
 * @property {string} industry_description
 * @property {string} industry_icon
 * @property {null} industry_icon_color
 * @property {null} industry_icon_url
 * @property {string} status
 * @property {object[]} groups
 * @property {number} groups.group_id
 * @property {string} groups.group_name
 * @property {string} groups.group_code
 * @property {string} groups.group_description
 * @property {string} groups.group_icon
 * @property {null} groups.group_icon_color
 * @property {null} groups.group_icon_url
 * @property {string} groups.status
 * @property {object[]} groups.categories
 * @property {number} groups.categories.category_id
 * @property {string} groups.categories.category_name
 * @property {string} groups.categories.category_code
 * @property {string} groups.categories.category_description
 * @property {string} groups.categories.category_icon
 * @property {null} groups.categories.category_icon_color
 * @property {null} groups.categories.category_icon_url
 * @property {string} groups.categories.status
 * @property {object[]} groups.categories.brands
 * @property {number} groups.categories.brands.brand_id
 * @property {string} groups.categories.brands.brand_name
 * @property {string} groups.categories.brands.brand_code
 * @property {null} groups.categories.brands.brand_description
 * @property {null} groups.categories.brands.brand_icon
 * @property {string} groups.categories.brands.brand_status
 * @property {object[]} groups.categories.criterias
 * @property {number} groups.categories.criterias.criteria_id
 * @property {string} groups.categories.criterias.criteria_value
 * @property {string} groups.categories.criterias.criteria_key
 * @property {null} groups.categories.criterias.criteria_icon
 * @property {null} groups.categories.criterias.criteria_status
 */

/**
 * @typedef Industry
 * @property {number} industry_id
 * @property {string} industry_code
 * @property {string} industry_name
 * @property {string} industry_icon
 * @property {string} industry_icon_url
 * @property {string} color
 */

/**
 * @typedef Group
 * @property {number} group_id
 * @property {string} group_code
 * @property {string} group_name
 * @property {string} group_icon
 * @property {string} group_icon_url
 * @property {string} group_icon_color
 * @property {Industry} v3_tbl_industry
 */

/**
 * @typedef Category
 * @property {number} category_id
 * @property {string} category_name
 * @property {string} category_code
 * @property {string} category_description
 * @property {string} category_icon
 * @property {string} category_icon_color
 * @property {string} category_icon_url
 * @property {string} status
 * @property {Group} v3_tbl_group
 */

/**
 * @typedef Brand
 * @property {number} brand_id
 * @property {string} brand_description
 * @property {string} brand_icon
 * @property {string} brand_name
 * @property {string} brand_code
 * @property {number} category_id
 * @property {string} category_code
 * @property {Category} category
 */

/**
 * @typedef initialStateModel
 * @type {object}
 * @property {Array<TDataLayerObject>} dataLayerList
 * @property {Array<TLegendColor>} legendColors
 * @property {object} dateListObject
 * @property {Array} industryList
 * @property {Array} industryListRinjani
 * @property {Industry} industryDetail
 * @property {Array} thematicCategoryList
 * @property {Array} thematicSubCategoryList
 * @property {Array} thematicItemList
 * @property {Array<Brand>} brands
 * @property {String} statusMessage_DO_ANALYZE
 * @property {String} statusCode_DO_ANALYZE
 * @property {'IDLE'|'SUCCESS'|'FAILED' | 'LOADING'} status_DO_ANALYZE
 * @property {'IDLE'|'SUCCESS'|'FAILED' | 'LOADING'} status_GET_LIST_DATE
 * @property {'IDLE'|'SUCCESS'|'FAILED' | 'LOADING'} status_GET_INDUSTRY_LIST
 * @property {'IDLE'|'SUCCESS'|'FAILED' | 'LOADING'} status_GET_GROUP_CATEGORY_BY_INDUSTRY
 * @property {'IDLE'|'SUCCESS'|'FAILED' | 'LOADING'} status_GET_THEMATIC_CATEGORY_LIST
 * @property {'IDLE'|'SUCCESS'|'FAILED' | 'LOADING'} status_GET_THEMATIC_SUBCATEGORY_LIST
 * @property {'IDLE'|'SUCCESS'|'FAILED' | 'LOADING'} status_GET_THEMATIC_ITEM_LIST
 * @property {'IDLE'|'SUCCESS'|'FAILED'|'LOADING'} status_GET_BRANDS
 */

/**
 * @type {initialStateModel} initialState
 */
const initialState = {
  dataLayerList: [],
  legendColors: [],
  dateListObject: {},
  industryList: [],
  industryListRinjani:[],
  industryDetail: {},
  brands: [],
  status_DO_ANALYZE: 'IDLE',
  status_GET_LIST_DATE: 'IDLE',
  status_GET_INDUSTRY_LIST: 'IDLE',
  status_GET_GROUP_CATEGORY_BY_INDUSTRY: 'IDLE',
  status_GET_BRANDS: 'IDLE',
};

export const datex_slice = createSlice({
  name: 'LI/mapbro/datex',
  initialState,
  reducers: {
    /**
     *
     * @param {object} action
     * @param {object} action.payload
     * @param {number} action.payload.id
     * @param {number} action.payload.sort_number
     * @param {object} action.payload.data
     */
    addFeatureToDataLayer(state, action) {
      const {
        payload: { data },
      } = action;
      state.dataLayerList.unshift(data);
    },
    /**
     *
     * @param {object} action
     * @param {object} action.payload
     * @param {object} action.payload.id
     */
    removeFeatureFromDatalayer(state, action) {
      const {
        payload: { id },
      } = action;
      state.dataLayerList = _.filter(state.dataLayerList, (v) => v?.id !== id);
    },
    /**
     *
     * @param {object} action
     * @param {object} action.payload
     * @param {Array<TDataLayerObject>} action.payload.arrOfData
     * @param {string} action.payload.sortKey
     */
    sortFeatureToDataLayer(state, action) {
      const {
        payload: { arrOfData, sortKey },
      } = action;
      state.dataLayerList = _.orderBy(arrOfData, [`${sortKey}`], ['asc']);
    },
    replaceFeatureToDataLayer(state, action) {
      const {
        payload: { data },
      } = action;
      state.dataLayerList = [...data];
    },
    resetState() {
      return initialState;
    },
    resetStatus(state) {
      state.status_DO_ANALYZE = 'IDLE';
    },
    getLegendColor(state) {
      state.status_GET_LIST_DATE = 'IDLE';
    },
  },
  extraReducers: (builder) =>
    builder
      //Analyze telco data
      .addCase(doAnalyzeTelcoData.pending, (state, action) => {
        state.status_DO_ANALYZE = 'LOADING';
        const id = action?.meta?.arg?.id;
        const newData = state.dataLayerList.map((el) =>
          el.id === id ? { ...el, status_ANALYZE: 'LOADING' } : el
        );
        state.dataLayerList = newData;
      })
      .addCase(doAnalyzeTelcoData.fulfilled, (state, action) => {
        state.status_DO_ANALYZE = 'SUCCESS';

        const { payload } = action;
        const result = payload.result;
        const id = payload.id;
        const newData = state.dataLayerList.map((el) =>
          el.id === id ? { ...el, result, status_ANALYZE: 'SUCCESS' } : el
        );
        state.dataLayerList = newData;
      })
      .addCase(doAnalyzeTelcoData.rejected, (state, action) => {
        const { payload } = action;
        state.statusCode_DO_ANALYZE = payload?.response?.status;
        if (state.statusCode_DO_ANALYZE === 500) {
          state.statusMessage_DO_ANALYZE = 'Data is Not Available in this area';
        } else if (
          state.statusCode_DO_ANALYZE === 524 ||
          state.statusCode_DO_ANALYZE === 408
        ) {
          state.statusMessage_DO_ANALYZE =
            'Load server has full, Please try again later';
        } else {
          state.statusMessage_DO_ANALYZE = payload?.response?.data?.message;
        }
        state.status_DO_ANALYZE = 'FAILED';
        const id = action?.meta?.arg?.id;
        state.dataLayerList = _.filter(
          state.dataLayerList,
          (v) => v?.id !== id
        );
      })
      // Analyze Datex POI
      .addCase(doAnalyzeDatexPoi.pending, (state, action) => {
        state.status_DO_ANALYZE = 'LOADING';
        const id = action?.meta?.arg?.id;
        const newData = state.dataLayerList.map((el) =>
          el.id === id ? { ...el, status_ANALYZE: 'LOADING' } : el
        );
        state.dataLayerList = newData;
      })
      .addCase(doAnalyzeDatexPoi.fulfilled, (state, action) => {
        state.status_DO_ANALYZE = 'SUCCESS';

        const { payload } = action;
        const { id, result } = payload;
        const newData = state.dataLayerList.map((el) => {
          return el.id === id ? { ...el, result, status_ANALYZE: 'SUCCESS' } : el
        }
          
        );
        state.dataLayerList = newData;
      })
      .addCase(doAnalyzeDatexPoi.rejected, (state, action) => {
        const { payload } = action;
        state.statusCode_DO_ANALYZE = payload?.response?.status;
        if (state.statusCode_DO_ANALYZE === 500) {
          state.statusMessage_DO_ANALYZE = 'Data is Not Available in this area';
        } else if (
          state.statusCode_DO_ANALYZE === 524 ||
          state.statusCode_DO_ANALYZE === 408
        ) {
          state.statusMessage_DO_ANALYZE =
            'Load server has full, Please try again later';
        } else {
          state.statusMessage_DO_ANALYZE = payload?.response?.data?.message;
        }
        state.status_DO_ANALYZE = 'FAILED';
        const id = action?.meta?.arg?.id;
        state.dataLayerList = _.filter(
          state.dataLayerList,
          (v) => v?.id !== id
        );
      })
      //Analyze Datex Thematic
      .addCase(doAnalyzeDatexThematic.pending, (state, action) => {
        state.status_DO_ANALYZE = 'LOADING';
        const id = action?.meta?.arg?.id;
        const newData = state.dataLayerList.map((el) =>
          el.id === id ? { ...el, status_ANALYZE: 'LOADING' } : el
        );
        state.dataLayerList = newData;
      })
      .addCase(doAnalyzeDatexThematic.fulfilled, (state, action) => {
        state.status_DO_ANALYZE = 'SUCCESS';
      
        const { payload } = action;
        let result = payload.result;
        const id = payload.id;
        if (result?.length > 0) {
          result = _.sortBy(payload.result, (v) => v.note);
        }
        const newData = state.dataLayerList.map((el) => {
          if (el.id === id) {
            let thematicSelectedYearIndex = 0;
            if (result?.length > 0) {
              result?.forEach((v, idx) => {
                if (
                  (v?.item?.map((v) => v.item_id) ?? [])?.toString() ===
                  el?.thematicItems?.toString()
                ) {
                  thematicSelectedYearIndex = idx;
                }
              });
            }
            return {
              ...el,
              thematicSelectedYearIndex,
              result,
              status_ANALYZE: 'SUCCESS',
            };
          }
          return el;
        });
        state.dataLayerList = newData;
      })
      .addCase(doAnalyzeDatexThematic.rejected, (state, action) => {
        const { payload } = action;
        state.statusCode_DO_ANALYZE = payload?.response?.status;
        if (state.statusCode_DO_ANALYZE === 500) {
          state.statusMessage_DO_ANALYZE = 'Data is Not Available in this area';
        } else if (
          state.statusCode_DO_ANALYZE === 524 ||
          state.statusCode_DO_ANALYZE === 408
        ) {
          state.statusMessage_DO_ANALYZE =
            'Load server has full, Please try again later';
        } else {
          state.statusMessage_DO_ANALYZE = payload?.response?.data?.message;
        }
        state.status_DO_ANALYZE = 'FAILED';
        const id = action?.meta?.arg?.id;
        state.dataLayerList = _.filter(
          state.dataLayerList,
          (v) => v?.id !== id
        );
      })
      // Get Legend color list
      .addCase(getLegendColor.pending, (state) => {
        state.status_GET_LEGEND_COLOR = 'LOADING';
      })
      .addCase(getLegendColor.fulfilled, (state, action) => {
        state.status_GET_LEGEND_COLOR = 'SUCCESS';
        const { payload } = action;
        const { result } = payload;
        state.legendColors = [...result];
      })
      .addCase(getLegendColor.rejected, (state) => {
        state.status_GET_LEGEND_COLOR = 'FAILED';
      })
      // Get disable date
      .addCase(doGetListDate.pending, (state) => {
        state.status_GET_LIST_DATE = 'LOADING';
      })
      .addCase(doGetListDate.fulfilled, (state, action) => {
        state.status_GET_LIST_DATE = 'SUCCESS';
        const { payload } = action;
        const result = payload.result;
        state.dateListObject = result;
      })
      .addCase(doGetListDate.rejected, (state) => {
        state.status_GET_LIST_DATE = 'FAILED';
      })
      // Get list industry
      .addCase(doGetIndustryList.pending, (state) => {
        state.status_GET_INDUSTRY_LIST = 'LOADING';
      })
      .addCase(doGetIndustryList.fulfilled, (state, action) => {
        state.status_GET_INDUSTRY_LIST = 'SUCCESS';
        const { payload } = action;
        const results = payload.results;
        state.industryList = results;
      })
      .addCase(doGetIndustryList.rejected, (state) => {
        state.status_GET_INDUSTRY_LIST = 'FAILED';
      })
      // Get list industry Rinjani
      .addCase(doGetIndustryListRinjani.pending, (state) => {
        state.status_GET_INDUSTRY_LIST_RINJANI = 'LOADING';
      })
      .addCase(doGetIndustryListRinjani.fulfilled, (state, action) => {
        state.status_GET_INDUSTRY_LIST_RINJANI = 'SUCCESS';
        const { payload } = action;
        const results = payload.result;
        state.industryListRinjani = results;
      })
      .addCase(doGetIndustryListRinjani.rejected, (state) => {
        state.status_GET_INDUSTRY_LIST_RINJANI = 'FAILED';
      })
      // get list of group and category
      .addCase(doGetIndustryDetail.pending, (state) => {
        state.status_GET_GROUP_CATEGORY_BY_INDUSTRY = 'LOADING';
        state.industryDetail = {};
      })
      .addCase(doGetIndustryDetail.fulfilled, (state, action) => {
        state.status_GET_GROUP_CATEGORY_BY_INDUSTRY = 'SUCCESS';
        const { payload } = action;
        const result = payload.result;
        state.industryDetail = result;
      })
      .addCase(doGetIndustryDetail.rejected, (state) => {
        state.status_GET_GROUP_CATEGORY_BY_INDUSTRY = 'FAILED';
      })
      // Get list thematic category
      .addCase(doGetThematicCategoryList.pending, (state) => {
        state.status_GET_THEMATIC_CATEGORY_LIST = 'LOADING';
        state.thematicCategoryList = undefined;
      })
      .addCase(doGetThematicCategoryList.fulfilled, (state, action) => {
        state.status_GET_THEMATIC_CATEGORY_LIST = 'SUCCESS';
        const { payload } = action;
        const result = payload.result;
        state.thematicCategoryList = result;
      })
      .addCase(doGetThematicCategoryList.rejected, (state) => {
        state.status_GET_THEMATIC_CATEGORY_LIST = 'FAILED';
      })
      // Get list thematic category Rinjani
      .addCase(doGetThematicCategoryListRinjani.pending, (state) => {
        state.status_GET_THEMATIC_CATEGORY_LIST_RINJANI = 'LOADING';
      })
      .addCase(doGetThematicCategoryListRinjani.fulfilled, (state, action) => {
        state.status_GET_THEMATIC_CATEGORY_LIST_RINJANI = 'SUCCESS';
        const { payload } = action;
        const result = payload.result;
        state.thematicCategoryListRInjani = result;
      })
      .addCase(doGetThematicCategoryListRinjani.rejected, (state) => {
        state.status_GET_THEMATIC_CATEGORY_LIST_RINJANI = 'FAILED';
      })
      // Get list thematic subcategory
      .addCase(doGetThematicSubCategoryList.pending, (state) => {
        state.status_GET_THEMATIC_SUBCATEGORY_LIST = 'LOADING';
        state.thematicSubCategoryList = undefined;
      })
      .addCase(doGetThematicSubCategoryList.fulfilled, (state, action) => {
        state.status_GET_THEMATIC_SUBCATEGORY_LIST = 'SUCCESS';
        const { payload } = action;
        const result = payload.result;
        state.thematicSubCategoryList = result;
      })
      .addCase(doGetThematicSubCategoryList.rejected, (state) => {
        state.status_GET_THEMATIC_SUBCATEGORY_LIST = 'FAILED';
      })
      // Get list thematic subcategory
      .addCase(doGetThematicSubCategoryListRinjani.pending, (state) => {
        state.status_GET_THEMATIC_SUBCATEGORY_LIST = 'LOADING';
        state.thematicSubCategoryList = undefined;
      })
      .addCase(doGetThematicSubCategoryListRinjani.fulfilled, (state, action) => {
        state.status_GET_THEMATIC_SUBCATEGORY_LIST = 'SUCCESS';
        const { payload } = action;
        const result = payload.result;
        state.thematicSubCategoryList = result;
      })
      .addCase(doGetThematicSubCategoryListRinjani.rejected, (state) => {
        state.status_GET_THEMATIC_SUBCATEGORY_LIST = 'FAILED';
      })
      // Get list thematic item
      .addCase(doGetThematicItemList.pending, (state) => {
        state.status_GET_THEMATIC_ITEM_LIST = 'LOADING';
        state.thematicItemList = undefined;
      })
      .addCase(doGetThematicItemList.fulfilled, (state, action) => {
        state.status_GET_THEMATIC_ITEM_LIST = 'SUCCESS';
        const { payload } = action;
        const result = payload.result;
        state.thematicItemList = result;
      })
      .addCase(doGetThematicItemList.rejected, (state) => {
        state.status_GET_THEMATIC_ITEM_LIST = 'FAILED';
      })
      // Get Brands v3
      .addCase(getBrands.pending, (state) => {
        state.status_GET_BRANDS = 'LOADING';
      })
      .addCase(getBrands.fulfilled, (state, action) => {
        state.status_GET_BRANDS = 'SUCCESS';
        const { payload } = action;
        const brands = payload.results;
        state.brands = brands || [];
      })
      .addCase(getBrands.rejected, (state) => {
        state.status_GET_BRANDS = 'FAILED';
      }),
});

export const { reducer: DATEX_REDUCER, actions: DATEX_ACTION } = datex_slice;

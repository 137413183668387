import React from 'react';
import DynamicIcon, { IconCalender } from '@bvt-icons';
import IconClock from '@bvt-assets/icon/icon-clock';

/**
 * @copyright Bhumi Varta Technology
 * @author Malik<malik.ibrahim@bvarta.com>
 * @param {Object} props
 * @param {String} props.area
 * @param {String} props.date
 * @param {String} props.time
 * @param {String} props.classname
 */

export const CardAreaDateTime = (props) => {
  const { area, date, time, className } = props;

  return (
    <div className={`CarDatexTelcoDataDetail__card ${className}`}>
      <div className='CarDatexTelcoDataDetail__card-header'>
        <span className='CarDatexTelcoDataDetail__card-header-title'>Area, Date and Time</span>
      </div>
      <div className='CarDatexTelcoDataDetail__card-body'>
        <div className='CarDatexTelcoDataDetail__card-adt'>
          <DynamicIcon
            className='CarDatexTelcoDataDetail__card-adt-icon'
            color='#07827C'
            height='1rem'
            icon='DropPinIcon'
            width='1rem'
          />
          <span className='CarDatexTelcoDataDetail__card-adt-text'>{area}</span>
        </div>
        <div className='CarDatexTelcoDataDetail__card-adt'>
          <IconCalender height={16} width={16} />
          <span className='CarDatexTelcoDataDetail__card-adt-text'>{date}</span>
        </div>
        <div className='CarDatexTelcoDataDetail__card-adt'>
          <IconClock height={16} width={16} />
          <span className='CarDatexTelcoDataDetail__card-adt-text'>{time}</span>
        </div>
      </div>
    </div>
  );
};

import React from 'react';

export const columnsDate  = [
  {
    title: () => {
      return <span className='CarDatexTelcoDataDetail__table-header'>Date</span>;
    },
    dataIndex: 'date',
    key: 'date',
    width: '10px',
    render: (key, record) => {
      return (
        <div className='CarDatexTelcoDataDetail__table-date'>
          <span>{record.date}</span>
        </div>
      );
    }
  },
  {
    title: () => {
      return <span className='CarDatexTelcoDataDetail__table-header'>Total/Day</span>;
    },
    dataIndex: 'total',
    key: 'total',
    render: (key, record) => {
      let maxWidth = (record.total / record.max) * 100;
      return (
        <div className='CarDatexTelcoDataDetail__table-bar' style={{ width: maxWidth+'%' }}>
          <span>{record.total}</span>
        </div>
      );
    }
  },
];

export const columnsTime = [
  {
    title: () => {
      return <span className='CarDatexTelcoDataDetail__table-header'>Time</span>;
    },
    dataIndex: 'date',
    key: 'date',
    width: '60px',
    render: (key, record) => {
      return (
        <div className='CarDatexTelcoDataDetail__table-date'>
          <span>{record.date}</span>
        </div>
      );
    }
  },
  {
    title: () => {
      return <span className='CarDatexTelcoDataDetail__table-header'>Total/Hour</span>;
    },
    dataIndex: 'total',
    key: 'total',
    render: (key, record) => {
      let maxWidth = (record.total / record.max) * 100;
      return (
        <div className='CarDatexTelcoDataDetail__table-bar' style={{ width: maxWidth+'%' }}>
          <span>{record.total}</span>
        </div>
      );
    }
  },
];
